import React from 'react';
import firebase from 'firebase/app';
import $ from "jquery";
/* this is all wrong. instead app decides what to render based on state and all sidebar does is render itslef and notify it's parent (app ) that a click (state change) has been made */
// App sets the intial active link: usage <Sidebar initialState={this.state.viewState} onLinkClick={this.appCallback} />
// and registrs the appropriate callback to change the app state on click
class ChangeAvail extends React.Component {
	constructor(props) {
		super(props);
    // get this state from firebase
		this.state = {
      n431sl: 'up',
      n556uh: 'up',
      n76dc: 'up',
      transit: 'up'
    };
    this.dbRef = firebase.database();
	}
  componentDidMount() {
    this.dbRef.ref('availability').once('value').then(function(snapshot2) {
      var stater = snapshot2.val();
      this.setState(stater);
    }.bind(this));
    this.dbRef.ref('availability').on('child_changed', function(snapshot) {
      var ac = snapshot.key;
      if (ac == 'n431sl')
      {
        this.setState({n431sl: snapshot.val()});
      }
      if(ac == 'n556uh')
      {
        this.setState({n556uh: snapshot.val()});
      }
      if(ac == 'n76dc')
      {
        this.setState({n76dc: snapshot.val()});
      }
      if (ac == 'transit')
      {
        this.setState({transit: snapshot.val()});
      }
    }.bind(this));
  }

  componentWillUnmount() {
   this.dbRef.ref('availability').off();
  }


	render() {
    return (

        <ul className="list-unstyled listfix">
  <li className="media">

    <div className="media-body">
      <h5 className="mt-0 mb-1">N431SL</h5>
     Status: {this.state.n431sl == 'up' ? 'Available':'Not Available'}

    </div>
  </li>
  <li className="media my-4">

    <div className="media-body">
      <h5 className="mt-0 mb-1">N556UH</h5>
     Status:  {this.state.n556uh == 'up' ? 'Available':'Not Available'}

    </div>
  </li>
  <li className="media">

    <div className="media-body">
      <h5 className="mt-0 mb-1">N76DC</h5>
      Status: {this.state.n76dc == 'up' ? 'Available' : 'Not Available'}

    </div>
  </li>

  <li className="media">

    <div className="media-body">
      <h5 className="mt-0 mb-1">Transit Van</h5>
      Status: {this.state.transit == 'up' ? 'Available' : 'Not Available'}

    </div>
  </li>
</ul>

      )
		}

}
export default ChangeAvail
