import React from 'react';
import firebase from 'firebase/app';
import $ from "jquery";
import logo from './logo-black.png';
import appad from './appad.png';
import Register from './Register';

class Signin extends React.Component {
	constructor(props) {
		super(props);
		this.state={
      email: '',
      password: '',
      error: '',
      disabled: false,
      emailFeedback: '',
      forgot: false,
			viewState: 'signin'
    }
	}

  componentDidMount() {

  }
  login(e) {
    e.preventDefault();
    // firebase login if successful
    this.setState({disabled: true});
    var email = this.state.email;
    var password = this.state.password;
    firebase.auth().signInWithEmailAndPassword(email, password).then(function() {
      //this.setState({});
      this.props.onLogin();
      this.setState({disabled: false});

  }.bind(this)).catch(function(error) {
  // Handle Errors here.
  var errorCode = error.code;
  var errorMessage = error.message;
  this.setState({error: errorMessage});
  this.setState({disabled: false});
}.bind(this));

  }
  onEmailInputChange(e) {
    this.setState({email: e.target.value});
  }
  onPasswordInputChange(e) {
    this.setState({password: e.target.value});
  }
  forgotPassword(e) {
    var auth = firebase.auth();
var emailAddress = this.state.email;

auth.sendPasswordResetEmail(emailAddress).then(function() {
  $('#regmes').removeClass('alert-danger');
  $('#regmes').addClass('alert-success');
  this.setState({error: "Password Reset Email Sent."});
}.bind(this)).catch(function(error) {
  // An error happened.
  this.setState({error: error.message});
}.bind(this));
  }
  register(e) {
    this.setState({viewState: 'register'});
  }
  terms(e) {
    this.props.onTerms();
  }
  onForgot(e) {
    e.preventDefault();
    this.setState({forgot: true});
  }
  onForgotSubmit(e) {
    e.preventDefault();
    var auth = firebase.auth();
var emailAddress = this.state.email;

auth.sendPasswordResetEmail(emailAddress).then(function() {
  // Email sent.
  this.setState({emailFeedback: "A reset password has been sent to your email."});
}.bind(this)).catch(function(error) {
  // An error happened.

  this.setState({error: error.message});
});
  }
  backToSignIn(e) {
    e.preventDefault();
    this.setState({forgot: false});
  }
	onRegisterBack() {

		this.setState({viewState: 'signin'});
	}
	render() {
    return (
      <div className="container">


			<div className="container col-xl-12 col-xxl-12 px-4 py-5">
    <div className="row align-items-center g-lg-5 py-5">
      <div className="col-lg-7 text-center text-lg-start">
        <img className="d-xs-block d-lg-none" src={logo} alt="Logo" width="250px"/>
				<img className="d-none d-lg-block" src={logo} alt="Logo" width="305px"/>

        <p className="col-lg-10 fs-4" style={{marginTop: "10px"}}>Track your flights, get timing and check availability for your logistics needs.</p>
				<small style={{marginBottom: "10px"}}>&copy; 2023 McMahon Helicopter Services, Inc.</small>
        <a href="https://apps.apple.com/us/app/mcmahon-now/id1448267733?platform=iphone"><img className="d-none d-lg-block" src={appad} alt="Logo" width="500px" style={{marginTop: "10px"}} /></a>
      </div>
      <div className="col-md-10 mx-auto col-lg-5">
        {this.state.viewState == 'signin' ? <form className="p-4 p-md-5 border rounded-3 bg-light">
				{this.state.error == '' ? '': <div id='regmes' className="alert alert-danger alert-dismissible fade show" role="alert">{this.state.error}</div>}
				{this.state.emailFeedback == '' ? '': <div id='regmes' className="alert alert-success alert-dismissible fade show" role="alert">{this.state.emailFeedback}</div>}

          <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={this.onEmailInputChange.bind(this)} value={this.state.email} maxLength="50" />
            <label htmlFor="floatingInput">Email address</label>
          </div>
					{this.state.forgot ? "" : <div className="form-floating mb-3">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={this.onPasswordInputChange.bind(this)} value={this.state.password} maxLength="25" />
            <label htmlFor="floatingPassword">Password</label>
          </div>}


					   {this.state.forgot ? <div id='forgotText' style={{marginTop: "-5px", marginBottom: '10px'}}><small>Type your email into the field and click submit to reset your password.</small></div> : ''}
					  {this.state.forgot ? <button className="btn w-100 btn-lg btn-primary btn-block" type="submit" onClick={this.onForgotSubmit.bind(this)}>Submit</button> : <button className="btn w-100 btn-lg btn-primary btn-block" type="submit" onClick={this.login.bind(this)} disabled={this.state.disabled ? 'disabled' : false}>Sign in</button>}

          <hr className="my-4" />

					{this.state.forgot ? <a href="#" onClick={this.backToSignIn.bind(this)}>Back to Sign In</a> : <div><a href="#" onClick={this.register.bind(this)}>Register</a> &middot; <a href="/terms.html" target="_blank" rel="noopener">Terms and Conditions</a> &middot; <a href="#" onClick={this.onForgot.bind(this)}>Forgot Password</a></div>}

        </form> : <Register onBack={this.onRegisterBack.bind(this)} />}
      </div>
      <a href="https://apps.apple.com/us/app/mcmahon-now/id1448267733?platform=iphone"><img className="d-block d-lg-none" src={appad} alt="Logo" width="500px" style={{marginTop: "10px"}} /></a>
    </div>
  </div>

    </div>
      )
		}

}
export default Signin
