import React from 'react';


import Navbar from './Navbar';
import Signin from './Signin';
import $ from "jquery";
import CustomerTrip2 from './CustomerTrip2';
import CustomerAvail from './CustomerAvail';
import MyAccount from './MyAccount';
import MobileNav from './MobileNav';
import Timing from './Timing';
import Register from './Register';
import HomeTripSearch from './HomeTripSearch';
import Availability from './Availability';
import Sidebar from './Sidebar';
import Past from './Past.js';
import Help from './Help.js';
import './sidebar.css';
import firebase from 'firebase/app';
import "firebase/auth";
//import { Loader } from "@googlemaps/js-api-loader";
const google = window.google;

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      loggedin: false,
      viewState: 'home',
      tripstate: {tripno: '', imei: ''},
      tripView: 'log'
    };
	}

  componentDidMount() {
    firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    // User is signed in.
    /*var displayName = user.displayName;
    var email = user.email;
    var emailVerified = user.emailVerified;
    var photoURL = user.photoURL;
    var isAnonymous = user.isAnonymous;
    var uid = user.uid;
    console.log(uid);
    var providerData = user.providerData;*/
     this.setState({viewState: 'home'});
  this.setState({loggedin: true});

  } else {
    this.setState({loggedin: false});

  }
}.bind(this));
/*     $(document).keypress(function(e) {
    if(e.which == 13) {
        e.preventDefault();
        if (this.state.viewState == 'home')
        {
          var tn = $('#track-input').val();
          if (tn != null);
          // signal home controller through property
          // to submit click
        }
    }
});*/
var styles = [
	{
			"featureType": "administrative",
			"elementType": "all",
			"stylers": [
					{
							"visibility": "on"
					},
					{
							"lightness": 33
					}
			]
	},
	{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
					{
							"color": "#d1d1d1"
					}
			]
	},
	{
			"featureType": "poi.business",
			"elementType": "all",
			"stylers": [
					{
							"visibility": "off"
					}
			]
	},
	{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
					{
							"color": "#deecdb"
					}
			]
	},
	{
			"featureType": "poi.park",
			"elementType": "labels",
			"stylers": [
					{
							"visibility": "on"
					},
					{
							"lightness": "25"
					}
			]
	},
	{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
					{
							"lightness": "25"
					}
			]
	},
	{
			"featureType": "road",
			"elementType": "labels.icon",
			"stylers": [
					{
							"visibility": "off"
					}
			]
	},
	{
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
					{
							"color": "#ffffff"
					}
			]
	},
	{
			"featureType": "road.highway",
			"elementType": "labels",
			"stylers": [
					{
							"saturation": "-90"
					},
					{
							"lightness": "25"
					}
			]
	},
	{
			"featureType": "road.arterial",
			"elementType": "all",
			"stylers": [
					{
							"visibility": "on"
					}
			]
	},
	{
			"featureType": "road.arterial",
			"elementType": "geometry",
			"stylers": [
					{
							"color": "#ffffff"
					}
			]
	},
	{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
					{
							"color": "#ffffff"
					}
			]
	},
	{
			"featureType": "transit.line",
			"elementType": "all",
			"stylers": [
					{
							"visibility": "off"
					}
			]
	},
	{
			"featureType": "transit.station",
			"elementType": "all",
			"stylers": [
					{
							"visibility": "off"
					}
			]
	},
	{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
					{
							"visibility": "on"
					},
					{
							"color": "#e0f1f9"
					}
			]
	}
];
window.map = new google.maps.Map(document.getElementById('map'), {
 zoom: 7,
 center: {lat: 42.452940, lng: -83.281291},
 mapTypeId: 'roadmap',
 styles: styles
});

/*const loader = new Loader({
  apiKey: "AIzaSyA3xjsghhis4wWrnAhzTVFuj8SbxVWY_CE",
	version: "weekly"
});
loader.load().then(() => {
	window.map = new google.maps.Map(document.getElementById('map'), {
	 zoom: 7,
	 center: {lat: 42.452940, lng: -83.281291},
	 mapTypeId: 'roadmap',
	 styles: styles
 });
});*/

  }
  componentWillUpdate() {

  }
  appStateCallback(newstate) {
    this.setState({viewState: newstate});
  }
  appLogout() {
    // logout firebase
    this.setState({loggedin: false});
  }
  appLogin() {
   /* this.setState({viewState: 'home'});
    this.setState({loggedin: true});*/
  }
  onBackClick() {
    this.setState({viewState: 'home'});
  }
  onTripSelect(tripno) {
    this.setState({tripstate: {tripno: tripno, imei: this.state.imei}});
    //console.log("Accoridng to customerapp the tripno is:"  + this.state.tripstate.tripno);
    this.setState({viewState: 'trip'});
  }
   onRegister() {
    this.setState({viewState: 'register'});
  }
  onRegistration() {
    this.setState({loggedin: true});
  }
  onRegisterBack() {
    this.setState({viewState: 'login'});
  }
  onCTMobileViewSelect(newView)
  {

    this.setState({tripView: newView});
  }
  termsClick(e) {
    e.preventDefault();
    //$('#exampleModal').modal('show');

  }
  privacyClick(e) {
    e.preventDefault();
  //  $('#exampleModal2').modal('show');
  }
	onGetTiming()
	{
		this.setState({viewState: 'timing'})
	}
render() {
    return (
       <div>
       {this.state.loggedin ?
				 	<div><Navbar onLinkClick={this.appStateCallback.bind(this)} onLogout={this.appLogout.bind(this)} />
          <div>

        {this.state.viewState == 'home'
          ? <HomeTripSearch onSubmitClick={this.onTripSelect.bind(this)} onRecentClick={this.onTripSelect.bind(this)} onGetTiming={this.onGetTiming.bind(this)} /> : this.state.viewState == 'availability' ?  <Availability /> : this.state.viewState == 'timing' ? <Timing/> :
           this.state.viewState == 'trip' ? <CustomerTrip2 tripno={this.state.tripstate.tripno} imei={this.state.tripstate.imei} onBack={this.onBackClick.bind(this)} mobileState={this.state.tripView} /> : this.state.viewState == 'account' ? <MyAccount /> : this.state.viewState == 'mytrips' ? <Past onTrip={this.onTripSelect.bind(this)} /> : this.state.viewState == 'help' ? <Help /> : <div>Nothing to show!</div>}
            </div></div> : <Signin onLogin={this.appLogin.bind(this)} onRegister={this.onRegister.bind(this)} />}

						</div>

     )
}

}

export default App;
