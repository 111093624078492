import React from 'react';
import firebase from 'firebase/app';
import $ from "jquery";
import {Modal} from 'bootstrap';

/* this is all wrong. instead app decides what to render based on state and all sidebar does is render itslef and notify it's parent (app ) that a click (state change) has been made */
// App sets the intial active link: usage <Sidebar initialState={this.state.viewState} onLinkClick={this.appCallback} />
// and registrs the appropriate callback to change the app state on click
class MyAccount extends React.Component {
	constructor(props) {
		super(props);
    // get the current advisory from firebase (subscribe)
		this.state = {
      nextEmail: '',
      oldEmail: '',
      oldPassword: '',
      nextPassword: '',
      nextFirstName: '',
      nextLastName: '',
      emailPass: '',
      passEmail: '',
      nameFeedback: '',
      nameState: '',
      passwordFeedback: '',
      passwordState: '',
      emailFeedback: '',
      emailState: '',
			company: '',
			profileState: '',
			profileFeedback: '',
			phoneNumber: '',
			jobTitle: ''
    };
		this.dbRef = firebase.database();
	}
  componentDidMount() {
		this.timingModal = new Modal(document.getElementById('deleteAccountModal'), {
	keyboard: true
});
var modl = document.getElementById('deleteAccountModal');
modl.addEventListener('bs.modal.hide', this.hm);
  }
componentWillUnmount() {
	var modl = document.getElementById('deleteAccountModal');
	modl.removeEventListener('bs.modal.hide', this.hm);
	this.timingModal.dispose();
}
hm()
{

		this.setState({confirmDelete: false});

}

 onEmailSubmit(e) {
  e.preventDefault();

var user = firebase.auth().currentUser;


var nextemail = this.state.nextEmail;
var email = this.state.oldEmail;
var password = this.state.emailPass;

firebase.auth().signInWithEmailAndPassword(email, password).then(function() {
       user.updateEmail(nextemail).then(function() {
        this.setState({emailState: 'good'});
  this.setState({emailFeedback: "Email changed successfully."});
  this.setState({nextEmail: ''});
this.setState({oldEmail: ''});
this.setState({emailPass: ''});
}.bind(this)).catch(function(error) {
  // An error happened.
  this.setState({emailState: 'bad'});
	var em = error.message;
		 this.setState({emailFeedback: em});
}.bind(this));

  }.bind(this)).catch(function(error) {
  // Handle Errors here.
  this.setState({emailState: 'bad'});
//  var errorCode = error.code;
  //var errorMessage = error.message;
	var em = error.message;
     this.setState({emailFeedback: em});

}.bond(this));

/*
user.reauthenticateWithCredential(credential).then(function() {
  // User re-authenticated.

}).catch(function(error) {
  // An error happened.
});*/


 }
 onPasswordSubmit(e) {
  e.preventDefault();

var user = firebase.auth().currentUser;


// Prompt the user to re-provide their sign-in credentials
// not sure if we use this method, aybe jus tht sign in with email/passwor dagain
var nextpass = this.state.nextPassword;
var email = this.state.passEmail;
var password = this.state.oldPass;

firebase.auth().signInWithEmailAndPassword(email, password).then(function() {
  // User re-authenticated.
  user.updatePassword(nextpass).then(function() {
  // Update successful.
  this.setState({passwordState: 'good'});
  this.setState({passwordFeedback: "Password changed successfully."});
  this.setState({nextPassword: ''});
this.setState({passEmail: ''});
this.setState({oldPass: ''});
}.bind(this)).catch(function(error) {
  // An error happened.
  this.setState({passwordState: 'bad'});
	var em = error.message;
     this.setState({passwordFeedback: em});
}.bind(this));
}.bind(this)).catch(function(error) {
  // An error happened.
	var em = error.message;
		 this.setState({passwordFeedback: em});
}.bind(this));


 }
 onNameSubmit(e) {
  e.preventDefault();
 var user = firebase.auth().currentUser;
var name = this.state.nextFirstName + ' ' + this.state.nextLastName;
user.updateProfile({
  displayName: name,
  //photoURL: "https://example.com/jane-q-user/profile.jpg"

}).then(function() {
  // Update successful.
  this.setState({nameState: 'good'});
  this.setState({nameFeedback: 'Name updated successfully.'});

  this.setState({nextFirstName: ''});
	this.setState({nextLastName: ''});
}.bind(this)).catch(function(error) {
  // An error happened.
  this.setState({nameState: 'bad'});
  this.setState({nameFeedback: 'Whoops! Something went wrong. Name did not update. Please contact support.'});

}.bind(this));

 }
	onProfileSave(e) {
		e.preventDefault();
		var user = firebase.auth().currentUser;
		if (this.validateCompany)
		{
			if (this.validatePhone)
			{
				if(this.validateTitle)
				{
					this.dbRef.ref('users/' + user.uid + '/company').set(this.state.company);
					this.dbRef.ref('users/' + user.uid + '/phone').set(this.state.phoneNumber);
					this.dbRef.ref('users/' + user.uid + '/title').set(this.state.jobTitle);
					this.setState({profileState: "good"});
					this.setState({profileFeedback: "Profile updated."});
				}
				else {
					this.setState({profileFeedback: "Job title must be less than 30 chracters."});
				}
			} else {
				this.setState({profileFeedback: "Phone number must be less than 15 chracters."});
			}
		} else {
			this.setState({profileFeedback: "Company name must be less than 30 chracters."});
		}

	}
 onFirstNameChange(e) {
  var newFirstName = e.target.value;
  this.setState({nextFirstName: newFirstName});
 }
 onLastNameChange(e) {
  var newLastName = e.target.value;
  this.setState({nextLastName: newLastName});
 }
  onOldEmailChange(e) {
  var oldemail = e.target.value;
  this.setState({oldEmail: oldemail});
 }
 onNewEmailChange(e) {
  var newemail = e.target.value;
  this.setState({nextEmail: newemail});
 }
 onChangeEmailPasswordInputChange(e) {
  var j = e.target.value;
  this.setState({emailPass: j});
 }
  onOldPasswordChange(e) {
  var oldpass = e.target.value;
  this.setState({oldPassword: oldpass});
 }
 onNewPassowordChange(e) {
  var newpass = e.target.value;
  this.setState({nextPassword: newpass});
 }
 onChangePasswordEmailInputChange(e) {
var j = e.target.value;
  this.setState({passEmail: j});
 }
   onDeleteAccount(e) {
    e.preventDefault();
    this.setState({confirmDelete: true});
    	this.timingModal.show();

  }
	validateTitle(title) {
		if (title.length > 29)
		{
			return false;
		}
		else
		{
			return true;
		}
	}
	validateCompany(company) {
		if (company.length > 29)
		{
			return false;
		}
		else
		{
			return true;
		}
	}
	validatePhone(pn) {
		if (pn.length > 29)
		{
			return false;
		}
		else
		{
			return true;
		}
 }
 onCompanyChange(e) {
	e.preventDefault();
	this.setState({company: e.target.value});
 }
 onJobTitleChange(e) {
		e.preventDefault();
		this.setState({jobTitle: e.target.value});
	}
	onPhoneNumberChange(e) {
		e.preventDefault();
		this.setState({phoneNumber: e.target.value});
	}
  cancelDelete(e) {
    e.preventDefault();
    this.setState({confirmDelete: false});
    	this.timingModal.hide();
  }
  confirmDeleteAccount(e) {
    e.preventDefault();



    var user = firebase.auth().currentUser;


          user.delete().then(function() {
            // User deleted.
            this.timingModal.hide();
            //this.setState({deleteError: "Account Deleted."});
            //this.props.onAccountDeletion();
            firebase.auth().signOut().then(function() {
                    // Sign-out successful.
                   // this.setState({confirmDelete: false});
  //  this.timingModal.hide();
                  }.bind(this)).catch(function(error) {
                    // An error happened.
                      //var errorMessage = error.message;
											this.timingModal.hide();
                     this.setState({deleteAccountError: "Account deleted, please logout."});
                    // this.props.onAccountDeletion();
                    this.setState({confirmDelete: false});

                    }.bind(this));

          }.bind(this)).catch(function(error) {
                  this.setState({deleteAccountError: "Whoops, an error occurred. If problem persists, email support."});
                  this.setState({confirmDelete: false});
    this.timingModal.hide();
                }.bind(this));

  }
	render() {
    return (
			<div className="row" style={{marginTop: '20px'}}>
				<div className="col-md-4">
				</div>
				<div className="col-sm-12 col-md-4">
				<div className="bg-light border rounded-3" id="form-outline" style={{padding: "20px"}}>

				<form className="needs-validation" noValidate>
					<div className="row g-3">
						<div className="col-12">
							<h5>Change Display Name</h5>
							{this.state.nameFeedback ? <div className={this.state.nameState == 'good' ? "alert alert-success" : "alert alert-danger"}>{this.state.nameFeedback}</div> : ''}
							<label htmlFor="firstName" className="form-label">First Name </label>
							<input type="text" className="form-control" id="fninput" aria-describedby="firstname" placeholder="First Name" onChange={this.onFirstNameChange.bind(this)} value={this.state.nextFirstName} maxLength="20" />
							<div className="invalid-feedback">
								First name is required.
							</div>
							<small id="emailHelp" className="form-text text-muted">First name must be less than 20 characters.</small>
						</div>

						<div className="col-12">
							<label htmlFor="address" className="form-label">Last Name</label>
							<input type="text" className="form-control" id="lninput" aria-describedby="lastname" placeholder="Last name" onChange={this.onLastNameChange.bind(this)} value={this.state.nextLastName} maxLength="20" />
							<div className="invalid-feedback">
								Please enter a last name.
							</div>
							<small id="emailHelp" className="form-text text-muted">Last name must be less than 20 characters.</small>
						</div>
<small id="emailHelp" className="form-text text-muted">Your display name is visible in chat messages and timing requests.</small>
<button type="submit" className="btn btn-primary" onClick={this.onNameSubmit.bind(this)}>Save Name</button>


					<div className="col-12">
						<hr />
					</div>

					<div className="col-12">
						<h5>Change Profile</h5>
						{this.state.profileFeedback ? <div className={this.state.profileState == 'good' ? "alert alert-success" : "alert alert-danger"}>{this.state.profileFeedback}</div> : ''}
						<label htmlFor="companyinput" className="form-label">Company </label>
						<input type="text" className="form-control" id="companyinput" aria-describedby="firstname" placeholder="Company Name" onChange={this.onCompanyChange.bind(this)} value={this.state.company} maxLength="29" />
						<div className="invalid-feedback">

						</div>
						<small id="companyhelp" className="form-text text-muted">Company name must be less than 30 characters.</small>
					</div>

					<div className="col-12">
						<label htmlFor="jobinput" className="form-label">Job Title</label>
						<input type="text" className="form-control" id="jobinput" aria-describedby="jobtitle" placeholder="Job Title" onChange={this.onJobTitleChange.bind(this)} value={this.state.jobTitle} maxLength="29" />
						<div className="invalid-feedback">

						</div>
						<small id="jobHelp" className="form-text text-muted">Job title must be less than 30 characters.</small>
					</div>

					<div className="col-12">
						<label htmlFor="phoneinput" className="form-label">Phone Number</label>
						<input type="tel" className="form-control" id="phoneinput" aria-describedby="phonenumber" placeholder="Phone Number" onChange={this.onPhoneNumberChange.bind(this)} value={this.state.phoneNumber} maxLength="29" />
						<div className="invalid-feedback">

						</div>
						<small id="phoneHelp" className="form-text text-muted">Phone number must be less than 15 characters.</small>
					</div>
<small id="profilehelp" className="form-text text-muted">Your company name is visible in chat messages and timing requests. Phone numbers are only visible to MHS staff and are not required, but may be helpful for our staff to provide trip updates.</small>
<button type="submit" className="btn btn-primary" onClick={this.onProfileSave.bind(this)}>Save Profile</button>

					<div className="col-12">
						<hr />
					</div>

					<div className="col-12">
						<h5>Change Email</h5>
						{this.state.emailFeedback ? <div className={this.state.emailState == 'good' ? "alert alert-success" : "alert alert-danger"}>{this.state.emailFeedback}</div> : ''}
						<label htmlFor="firstName" className="form-label">Old Email </label>
						<input type="email" className="form-control" id="oldemailinput" aria-describedby="emailHelp" placeholder="Enter email" onChange={this.onOldEmailChange.bind(this)} value={this.state.oldEmail} />
						<div className="invalid-feedback">
							Old email is required.
						</div>
					</div>

					<div className="col-12">
						<label htmlFor="address" className="form-label">New Email</label>
						<input type="email" className="form-control" id="newemailinput" aria-describedby="emailHelp" placeholder="Enter email" onChange={this.onNewEmailChange.bind(this)} value={this.state.nextEmail} />
						<div className="invalid-feedback">
							Please enter a new email address.
						</div>

					</div>

					<div className="col-12">
						<label htmlFor="address" className="form-label">Password</label>
						<input type="password" className="form-control" id="passwordinput" placeholder="Password" onChange={this.onChangeEmailPasswordInputChange.bind(this)} value={this.state.emailPass} />
						<div className="invalid-feedback">
							Please enter your password.
						</div>

					</div>
<button type="submit" className="btn btn-primary" onClick={this.onEmailSubmit.bind(this)}>Save Email</button>
			<div className="col-12">
				<hr />
			</div>
			<div className="col-12">
				<h5>Change Password</h5>
				{this.state.passwordFeedback ? <div className={this.state.passwordState == 'good' ? "alert alert-success" : "alert alert-danger"}>{this.state.passwordFeedback}</div> : ''}
				<label htmlFor="firstName" className="form-label">Email Address</label>
				<input type="email" className="form-control" id="changepassemailinput" aria-describedby="emailHelp" placeholder="Enter email" onChange={this.onChangePasswordEmailInputChange.bind(this)} value={this.state.passEmail} />
				<div className="invalid-feedback">
					Your email is required.
				</div>
			</div>

			<div className="col-12">
				<label htmlFor="address" className="form-label">Old Password</label>
				<input type="password" className="form-control" id="changpasspassinput" placeholder="Password"  value={this.state.oldPassword} onChange={this.onOldPasswordChange.bind(this)} />
				<div className="invalid-feedback">
					Please enter a new password.
				</div>

			</div>

			<div className="col-12">
				<label htmlFor="address" className="form-label">New Password</label>
				<input type="password" className="form-control" id="changepassnewpassinput" placeholder="Password" value={this.state.nextPassword} onChange={this.onNewPassowordChange.bind(this)} maxLength="15"/>
				<div className="invalid-feedback">
					Please enter your new password.
				</div>
<small id="emailHelp" className="form-text text-muted">Passwords must be between 5 and 16 characters.</small>
			</div>
<button type="submit" className="btn btn-primary" onClick={this.onPasswordSubmit.bind(this)}>Save Password</button>
				<div className="col-12">
					<hr />
				</div>







	</div>







					<button type="submit" className="btn btn-primary" onClick={this.onDeleteAccount.bind(this)}>Delete Account</button>
					<div className="modal fade" id="deleteAccountModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
					     <div className="modal-dialog">
					        <div className="modal-content">
					            <div className="modal-header">

					              <h4 className="modal-title" id="myModalLabel">Delete Account</h4>
					              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					            </div>
					            <div className="modal-body">



					                {this.state.deleteAccountError ? <div className="alert alert-danger" role="alert">{this.state.deleteAccountError}</div> : ''}
					                <p>Are you sure you want to delete your account? This action is permanent and cannot be undone.</p>
					               <button id="deleteButton" className="btn btn-danger" onClick={this.confirmDeleteAccount.bind(this)}>Delete Account</button>
					              </div>

					        </div>
					      </div>

					</div>
				</form>
	</div>
			  </div>
		  	<div className="col-md-4">
				</div>
		 </div>

      )
		}

}
export default MyAccount
