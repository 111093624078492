import React from 'react';
import firebase from 'firebase/app';
import {Dropdown} from 'bootstrap';
import AvailabilityMap from './AvailabilityMap';

class Availability extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

    };
    this.dbRef = firebase.database();
	}
  componentDidMount() {
		// component reserved fir future information o  a.c and pilots etc.

  }
	componentWillUnmount() {

	}



	render() {
    return (
    <div className="col-12" style={{margin: 0, padding: 0, width: '100%', height: '100%'}} id="mapsizer">

			<AvailabilityMap />

    </div>
      )
		}

}
export default Availability
