import React from 'react';
import firebase from 'firebase/app';
//import { Loader } from "@googlemaps/js-api-loader";
import $ from "jquery";
const google = window.google;
// requires global variables window.map, initMap(), and jquery from index.html
// takes three properties, from and to, and type (ground or air, to know which lines to draw)
// calculates distance (Google)
// calculates directions and ETE (google)
// displays directions
// draws straight line between (if type air only)
// calculates time (us)

// only calculates when calculate property is set true
class TimingMap extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
				airete: '',
				groundete: ''
    };
    this.dbRef = firebase.database();


    // erase any existing lines/icons
    if (window.shipicon)
    {
      window.shipicon.setMap(null);
    }
		if (window.shipicon1)
		{
			window.shipicon1.setMap(null);
		}
		if (window.shipicon2)
    {
      window.shipicon2.setMap(null);
    }
		if (window.shipicon3)
    {
      window.shipicon3.setMap(null);
    }
    if (window.line)
    {
      window.line.setMap(null);
    }
		if (window.to)
		{
			window.to.setMap(null);
		}
		if (window.from)
		{
			window.from.setMap(null);
		}
		if (window.forwardline)
		{
			window.forwardline.setMap(null);
		}
		if (window.hangar)
		{
			window.hangar.setMap(null);
		}
		window.hangar = new google.maps.Marker({



	 position: {lng: -83.4550, lat: 42.3472},
	 map: window.map,
	 icon: {
				 path: google.maps.SymbolPath.CIRCLE,
			 scale: 6,
			 fillColor: "#08B2FC",
			 fillOpacity: 0.8,
			 strokeWeight: 2,
			 rotation: 0 //this is how to rotate the pointer
	 }
});
		 window.to = new google.maps.Marker({



    position: {lng: -83.4550, lat: 42.3472},
    map: window.map,
    icon: {
          path: google.maps.SymbolPath.CIRCLE,
        scale: 6,
        fillColor: "#08B2FC",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0 //this is how to rotate the pointer
    }
});
			window.from = new google.maps.Marker({



			position: {lng: -83.4550, lat: 42.3472},
			map: window.map,
			icon: {
					 path: google.maps.SymbolPath.CIRCLE,
				 scale: 6,
				 fillColor: "#08B2FC",
				 fillOpacity: 0.8,
				 strokeWeight: 2,
				 rotation: 0 //this is how to rotate the pointer
			}
			});
			if (this.props.type == 'air')
			{
		      window.linedata = [{lng: -83.4550, lat: 42.3472}];

					var lineSymbol = {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
					strokeColor: '#08B2FC',
					strokeWeight: 4,
          scale: 4
        };
				window.line = new google.maps.Polyline({
				path: window.linedata,
				strokeOpacity: 0,
				icons: [{
					icon: lineSymbol,
					offset: '0',
					repeat: '20px'
				}],
				map: window.map
			});
		    /*  window.line = new google.maps.Polyline({
		                  path: window.linedata,
		                  geodesic: true,
		                  strokeColor: '#08B2FC',
		                  strokeOpacity: 1.0,
		                  strokeWeight: 4,

										});*/
		       window.line.setMap(window.map);
				}
				this.directionsService = new google.maps.DirectionsService();
			  this.directionsDisplay = new google.maps.DirectionsRenderer();

			  this.directionsDisplay.setMap(window.map);
	}

  componentDidMount() {


        //window.map.flyTo({center: this.state.positions[poslength]});*/
				var maper = $('#map');
				var hei = $('#form-outline').height();
				var wid = $('#size-target').width();
								$('#map').css('width', wid);

						    $('#map').css('height', hei);
		    $('#map-target').append(maper);

				//$('#map').show();
				$(window).resize(function() {
					google.maps.event.trigger(window.map, 'resize');
			});


					google.maps.event.trigger(window.map, 'resize');



  }
  componentWillReceiveProps(nextProps)
  {

  }
  componentDidUpdate() {
		var gotTo = false;
		var gotFrom = false;
	//	console.log('updating props');

if (this.props.to != undefined && this.props.to != '')
{

	gotTo = true;
}
if (this.props.from != undefined && this.props.from != '')
{

	 gotFrom = true;
}
console.log('gotTo:' + gotTo + ' gotFrom:' + gotFrom);
if (gotTo && gotFrom)
{

/* this was commented out to prevent auto calc, instead in the new version we wait for the button press which
is sent to the "submitted" property
	if (this.props.type == 'air')
	{
		this.determineTypeAndDrawIt();
	}
	this.calculateDirections();*/
	if (this.props.submitted)
	{
		this.determineTypeAndDrawIt();
	}
}

  }
  componentWillUnmount() {
    //window.map.removeLayer('route');
		google.maps.event.clearListeners(window.map, 'resize');
		var mapr = $('#map');
		$('#maphider').append(mapr);
		 $('#map-target').empty();
  }
	calculateDirections()
	{
		//console.log('calculating');
		// also get ground ETE
		// add 20 minutes during business hours
		// add 1 hour after hours


  /*var from = new google.maps.LatLng(this.props.from.lat, this.props.from.lng);
  var to = new google.maps.LatLng(this.props.to.lat, this.props.from.lng);*/
  //var selectedMode = document.getElementById('mode').value;
  var request = {
      origin: {lng: -83.4550, lat: 42.3472},
      destination: this.props.to,
      // Note that Javascript allows us to access the constant
      // using square brackets and a string value as its
      // "property."
			waypoints: [{location: this.props.from, stopover: true}],
      travelMode: google.maps.TravelMode['DRIVING']
  };
  this.directionsService.route(request, function(response, status) {
    if (status == 'OK') {
		//	console.log('ok');
      this.directionsDisplay.setDirections(response);
			var ete = response.routes[0].legs[0].duration.value; // duration of trip in seconds
			var total = (ete + (60*20))/60;
			this.props.onGroundETE(total);
		/*	var etepre = Date.now(); // date stamp of now
			var ete = eta - etepre; // ete = then - now;
			var newete = ete + 3600000; // 60 mins = 1000ms/s * 60s/min * 60min
			// if time during business hours 20 mins disp + 20 mins load if over 500 mi, 20 mins fuel
			// if time outside business hours 60 mins + 20 mins load, if over 500 mi, 20 mins fuel
			this.setState({groundete: newete});
			this.props.onGroundETE(this.state.groundete);*/
    }
		else {
		//	console.log('calc failed');
		}
  }.bind(this));

	}
	determineTypeAndDrawIt()
	{
		if (this.props.landTo && this.props.landFrom)
		{
			// just air ete
			this.calculateAirETE(0, 0);
		}
		else if (this.props.landTo && !this.props.landFrom)
		{
			// add gound ete at destination, need to draw additonal ground line lz to dropoff
			this.directionsService2 = new google.maps.DirectionsService();
			this.directionsDisplay2 = new google.maps.DirectionsRenderer();

			this.directionsDisplay2.setMap(window.map);
			var fromliteral = {};
			fromliteral.lat = Number(this.props.fromLZ.lat);
			fromliteral.lng = Number(this.props.fromLZ.lng);
			var request = {
		      origin: fromliteral,
		      destination: this.props.from,
		      // Note that Javascript allows us to access the constant
		      // using square brackets and a string value as its
		      // "property."
		      travelMode: google.maps.TravelMode['DRIVING']
		  };
		  this.directionsService2.route(request, function(response, status) {
		    if (status == 'OK') {
				//	console.log('ok');
		      this.directionsDisplay2.setDirections(response);
					var ete = response.routes[0].legs[0].duration.value; // duration of trip in seconds
					// add 20 mins for loading
					var total = (ete + (60*20))/60;
					this.calculateAirETE(total, 0);
				/*	var etepre = Date.now(); // date stamp of now
					var ete = eta - etepre; // ete = then - now;
					var newete = ete + 3600000; // 60 mins = 1000ms/s * 60s/min * 60min
					// if time during business hours 20 mins disp + 20 mins load if over 500 mi, 20 mins fuel
					// if time outside business hours 60 mins + 20 mins load, if over 500 mi, 20 mins fuel
					this.setState({groundete: newete});
					this.props.onGroundETE(this.state.groundete);*/
		    }
				else {
					//console.log('calc failed');
				}
		  }.bind(this));

		}
		else if (this.props.landFrom && !this.props.landTo)
		{
			// add ground ete for pickup draw additonal ground line pickup to lz
			this.directionsService3 = new google.maps.DirectionsService();
			this.directionsDisplay3 = new google.maps.DirectionsRenderer();

			this.directionsDisplay3.setMap(window.map);
			var toliteral = {};
			toliteral.lat = Number(this.props.toLZ.lat);
			toliteral.lng = Number(this.props.toLZ.lng);
			var request = {
		      origin: this.props.to,
		      destination: toliteral,
		      // Note that Javascript allows us to access the constant
		      // using square brackets and a string value as its
		      // "property."
		      travelMode: google.maps.TravelMode['DRIVING']
		  };
		  this.directionsService3.route(request, function(response, status) {
		    if (status == 'OK') {
				//	console.log('ok');
		      this.directionsDisplay3.setDirections(response);
					var ete = response.routes[0].legs[0].duration.value; // duration of trip in seconds
					// add 20 mins for loading
					var total = (ete + (60*20))/60;
					this.calculateAirETE(total, 0);
				/*	var etepre = Date.now(); // date stamp of now
					var ete = eta - etepre; // ete = then - now;
					var newete = ete + 3600000; // 60 mins = 1000ms/s * 60s/min * 60min
					// if time during business hours 20 mins disp + 20 mins load if over 500 mi, 20 mins fuel
					// if time outside business hours 60 mins + 20 mins load, if over 500 mi, 20 mins fuel
					this.setState({groundete: newete});
					this.props.onGroundETE(this.state.groundete);*/
		    }
				else {
					//console.log('calc failed');
				}
		  }.bind(this));
		}
		else {
			// cant land either place, (e.g. pickup from Livonia, deliver to lousiville)
			this.directionsService2 = new google.maps.DirectionsService();
			this.directionsDisplay2 = new google.maps.DirectionsRenderer();

			this.directionsDisplay2.setMap(window.map);
			var fromliteral = {};
			fromliteral.lat = Number(this.props.fromLZ.lat);
			fromliteral.lng = Number(this.props.fromLZ.lng);
			var request = {
		      origin: fromliteral,
		      destination: this.props.from,
		      // Note that Javascript allows us to access the constant
		      // using square brackets and a string value as its
		      // "property."
		      travelMode: google.maps.TravelMode['DRIVING']
		  };
			var promise1 = new Promise(function(resolve, reject) {
				this.directionsService2.route(request, function(response, status) {
					if (status == 'OK') {
					//	console.log('ok');
						this.directionsDisplay2.setDirections(response);
						var ete = response.routes[0].legs[0].duration.value; // duration of trip in seconds
						// add 20 minutes for loading
						var total = (ete + (60*20))/60;
						//this.calculateAirETE(total);
						this.setState({ground1: total});
						resolve();
					}
					else {
						//console.log('calc failed');
						reject();
					}
				}.bind(this));
			});


			this.directionsService3 = new google.maps.DirectionsService();
			this.directionsDisplay3 = new google.maps.DirectionsRenderer();

			this.directionsDisplay3.setMap(window.map);
			var toliteral = {};
			toliteral.lat = Number(this.props.toLZ.lat);
			toliteral.lng = Number(this.props.toLZ.lng);
			var request2 = {
		      origin: this.props.to,
		      destination: toliteral,
		      // Note that Javascript allows us to access the constant
		      // using square brackets and a string value as its
		      // "property."
		      travelMode: google.maps.TravelMode['DRIVING']
		  };
			var promise2 = new Promise(function(resolve, reject) {
				this.directionsService3.route(request2, function(response, status) {
				 if (status == 'OK') {
				 //	console.log('ok');
					 this.directionsDisplay3.setDirections(response);
					 var ete = response.routes[0].legs[0].duration.value; // duration of trip in seconds
					 // add 20 mins for loading
					 var total = (ete + (60*20))/60;
					 //this.calculateAirETE(total);
					 this.setState({ground2: total});
					 resolve();
				 }
				 else {
					 console.log('calc failed');
					 reject();
				 }
			 }.bind(this));
			});
		 	 Promise.all([promise1, promise2]).then(() => {
				 	this.calculateAirETE(this.state.ground1, this.state.ground2);
			 });
		}
	}
calculateAirETE(additionalTime, additionalTime2)
	{

		const hangarmarker = new google.maps.Marker({
    // The below line is equivalent to writing:
    // position: new google.maps.LatLng(-34.397, 150.644)
    position: {lat: 42.3472, lng: -83.4550}
    //map: window.map,
  });
		var hangarGMapsobj = hangarmarker.getPosition(); // returns a google maps latlng obj for the compute distance function
		if (this.props.landFrom && this.props.landTo)
		{
				var meters = google.maps.geometry.spherical.computeDistanceBetween(this.props.from, this.props.to);
			 var meters2 = google.maps.geometry.spherical.computeDistanceBetween(hangarGMapsobj, this.props.from);
		}
		else if (!this.props.landFrom && this.props.landTo)
		{
				var meters = google.maps.geometry.spherical.computeDistanceBetween(this.props.fromLZ, this.props.to);
					 var meters2 = google.maps.geometry.spherical.computeDistanceBetween(hangarGMapsobj, this.props.fromLZ);
		}
		else if (!this.props.landFrom && !this.props.landTo)
		{
			var meters = google.maps.geometry.spherical.computeDistanceBetween(this.props.fromLZ, this.props.toLZ);
				 var meters2 = google.maps.geometry.spherical.computeDistanceBetween(hangarGMapsobj, this.props.fromLZ);
		}
		// need to add this also
//  mget mileag friom meters
var mileage = meters/1609.34;
var mileage2 = meters2/1609.34;

	 var time0 = mileage2/155*60; // minutes
	 //console.log('leg 1 time: ' + time0 + 'mins');
		var time1 = mileage/155*60; //minutes
		//console.log('leg 2 time: ' + time1 + 'mins');
		var time2 = 0;
		var totalmileage = mileage + mileage2;
		if (totalmileage > 260)
		{
			// fuel stop required
			 time2 = 20;
			 //console.log('1 fuel stop 20 mins');
		}
		if (totalmileage > 520)
		{
			time2 = 40;
			//console.log('2 fuel stops of 20 mins each');
		}
		if (totalmileage > 780)
		{
			time2 = 60;
			//console.log('3 fuel stops 20 mins each');
		}
		var time3 = 20; //loading
		//console.log('20 mins loading');
		var ete = (additionalTime + additionalTime2 + time1 + time0 + time2 + time3); //minutes

 //console.log('eteaftertimingmap: ' + ete);
		//this.setState({airete: ete});
		this.props.onAirETE(ete);
	}

	render() {

		if (this.props.type == 'air')
		{
			if (this.props.to != undefined && this.props.from != undefined && this.props.to != '' && this.props.from != '')
			{
				window.linedata = [];
				window.linedata = [{lng: -83.4550, lat: 42.3472}];
				// add logic of landfrom and landto
				if (this.props.landFrom && this.props.landTo)
				{
					window.linedata.push(this.props.from);
					window.linedata.push(this.props.to);
				}
				else if (!this.props.landFrom && this.props.landTo)
				{
					window.linedata.push(this.props.fromLZ);
					window.linedata.push(this.props.to);
				}
				else if (!this.props.landFrom && !this.props.landTo)
				{
					window.linedata.push(this.props.fromLZ);
					window.linedata.push(this.props.toLZ);
				}

				window.line.setPath(window.linedata);
			}
		}




if (this.props.to != undefined && this.props.to != '')
{
	window.to.setPosition(this.props.to);

}
if (this.props.from != undefined && this.props.from != '')
{
	 window.from.setPosition(this.props.from);

}

 //window.shipicon.setIcon(ptr);

        //window.map.setCenter();

		return (
      <div id="mapcontainer" className="nopadding" style={{padding: "0px", margin: "0px"}}>
        <div id="map-target"></div>
         </div>
      )
	}
}

export default TimingMap
