import React from 'react';
import firebase from 'firebase/app';
import $ from "jquery";
import "firebase/auth";
import './messenger.css';
import {Offcanvas} from 'bootstrap';
import {ReactComponent as ChatIcon} from './icons/chat-dots.svg';
import {ReactComponent as Avatar} from './icons/person-circle.svg';
import logo from './logo-black.png';

class Messenger extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
			open: false,
			chatInputVal: '',
			data: [{uid: '', name: "Nate", content: 'Welcome! You can use this to chat with McMahon Dispatch team members to get real time trip information. Just type a message and click Send. Or, as always, you can contact us on the phone at 734-459-5980 for immediate answers.', avatar: 'https://go.mcmahonnow.com/images/nate.png', company: 'McMahon Team'}],
			userAvatar: 'images/avatar.png',
			userCompany: '',
			needUsername: false,
			name: ''
		};
		this.dbRef = firebase.database();
    this.uid = firebase.auth().currentUser.uid;
    this.displayName = firebase.auth().currentUser.displayName;

	}

  componentDidMount() {
		if (this.displayName == undefined || this.displayName == '' || this.displayName == null || this.displayName.length < 1)
		{
			this.setState({needUsername: true});
		}
		else {
			this.setState({needUsername: false});
		}
   this.dbRef.ref('active/' + this.props.tripid + '/messages').on('child_added', function(snapshot) {

      var newtrips = this.state.data;
      newtrips.push(snapshot.val());
      //console.log(newtrips);
      this.setState({data: newtrips});


    }.bind(this));
   this.dbRef.ref('users/' + this.uid + '/avatar').once('value').then((snap) => {
    if (snap.exists())
    {
      this.setState({userAvatar: snap.val()});
    }
	});
	this.dbRef.ref('users/' + this.uid + '/company').once('value').then((snap) => {
	 if (snap.exists())
	 {
		 this.setState({userCompany: snap.val()});
	 }
 });

/*	var offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'))
var offcanvasList = offcanvasElementList.map(function (offcanvasEl) {
  this.offcanvas = new Offcanvas(offcanvasEl);
}.bind(this));*/
var offcanvasEl = $('#chatterbox1')[0];
  this.offcanvas = new Offcanvas(offcanvasEl);
	var myOffcanvas = document.getElementById('chatterbox1');
	myOffcanvas.addEventListener('shown.bs.offcanvas', function () {
		var sh = $('#messages-area')[0].scrollHeight;
	var ch = $('#messages-area')[0].clientHeight;
	var scrollHeight = Math.max(sh, ch);
	$('#messages-area').scrollTop(scrollHeight);
});

  }
  componentWillUnmount() {
    this.dbRef.ref('active/' + this.props.tripid + '/messages').off();
		this.offcanvas.dispose();
  }
  componentDidUpdate() {

       var sh = $('#messages-area')[0].scrollHeight;
    var ch = $('#messages-area')[0].clientHeight;
    var scrollHeight = Math.max(sh, ch);
    $('#messages-area').scrollTop(scrollHeight);
    

  }
  onInputChange(e) {
    this.setState({chatInputVal: e.target.value});
  }
  sendClick(e) {
    e.preventDefault();
		if (!this.state.needUsername || this.state.name.length > 0)
		{
			this.setState({needName: false});
			this.setState({needUsername: false});
			    if (this.state.chatInputVal != '')
			    {
			    var newmessage = {};
			    newmessage.uid = this.uid;
			    newmessage.content = this.state.chatInputVal;
					if (this.displayName)
					{
						newmessage.name = this.displayName;
					}
					else{
						newmessage.name = this.state.name;
					}

			    newmessage.avatar = this.state.userAvatar;
					if (this.state.userCompany.length > 0 && this.state.userCompany != undefined)
					{
						newmessage.company = this.state.userCompany;
					}
					else {
						newmessage.company = '';
					}

					var datnhj =  new Date();
					var djs = datnhj.getMonth();
					var monthnow = Number(djs) + 1;

					var today = monthnow + '/' + datnhj.getDate() + '/' + datnhj.getFullYear();
					var dfg = datnhj.getMinutes();
					if (dfg < 10)
					{
						dfg = '0' + dfg;
					}
					var rightnow = datnhj.getHours() + ':' + dfg;
			    newmessage.timestamp = today + ' at ' + rightnow;
			    console.log(newmessage);
			    this.dbRef.ref('active/' + this.props.tripid + '/messages').push(newmessage);
			   this.setState({chatInputVal: ''});
				 var sh = $('#messages-area')[0].scrollHeight;
			var ch = $('#messages-area')[0].clientHeight;
			var scrollHeight = Math.max(sh, ch);
			$('#messages-area').scrollTop(scrollHeight);
					if (this.state.needUsername && this.state.name.length > 0)
					{
						var user = firebase.auth().currentUser;
						var name = this.state.name;
						user.updateProfile({
						  displayName: name,


						}).then(function() {

						}.bind(this)).catch(function(error) {

						}.bind(this));
					}

			  }
			}
			else {
				this.setState({needName: true});
			}
  }
  openClick(e) {
    e.preventDefault();
    this.setState({open: true});

  }
  close(e) {
    e.preventDefault();
    this.setState({open: false});
  }
	onNameChange(e)
	{
		this.setState({name: e.target.value});
		this.setState({needName: false});
	}
	render() {
  const listOfMessages = this.state.data.map(function(message, idx) {
              if (message.uid == this.uid)
              {
                return (


  <div key={idx} className="a-message">
    <div className="a-message-header">
      <div id="picture-frame">
        <img className="rounded" src={this.state.userAvatar ? this.state.userAvatar :   'https://go.mcmahonnow.com/images/avatar.png'  } />
      </div>
      <div id="name"><span className="bolded">{message.name}</span> {message.company ? <span>from {message.company}</span> : ''}</div>
    </div>

    <div className="a-message-content">
      {message.content}
    </div>
	{message.timestamp ? <small>{message.timestamp}</small> : ''}
  </div>

                  )
              }
              else
              {
                return (
                 <div key={idx} className="b-message">
    <div className="b-message-header">
      <div id="picture-frame">
        <img className="rounded" src={message.avatar ?  message.avatar :   'https://go.mcmahonnow.com/images/avatar.png' } />
      </div>
      <div id="name"><span className="bolded">{message.name}</span> {message.company ? <span>from {message.company}</span> : ''}</div>
    </div>

    <div className="b-message-content">
      {message.content}
    </div>
		{message.timestamp ? <small>{message.timestamp}</small> : ''}
  </div>
               )
              }

            }.bind(this));
		return (

			<div>




				 <div id="chatterbox1" className="offcanvas offcanvas-end" tabIndex="-1" data-bs-scroll="true" aria-labelledby="offcanvasExampleLabel">
			  <div className="offcanvas-header">
			    <img src={logo} alt="Logo" width="150px" style={{}}/>
			    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			  </div>

			  <div className="offcanvas-body">
				<div id="messages-area">
				{listOfMessages}
				</div>
				<hr />
				{this.state.needName ? <div className="alert alert-primary">Please enter a name to chat.</div> : ''}
				{this.state.needUsername ? <div>

				<div className="col-12">
					<label htmlFor="name" className="form-label">Name</label>
					<input id="name" type="text" className="form-control" placeholder="Your name (e.g. John Smith)" required onChange={this.onNameChange.bind(this)} value={this.state.name} maxLength="30" />
					<div className="invalid-feedback">
					</div>
					<small>Please enter a name to chat.</small>

				</div></div> : ''}
				<div id="send-area" className="input-group">
					<input type="text" className="form-control" placeholder="Write a message..." value={this.state.chatInputVal} onChange={this.onInputChange.bind(this)} maxLength="150" />
					<button className="btn btn-primary" onClick={this.sendClick.bind(this)}>Send</button>
				</div>
			  </div>
			</div>
             <div id="fixedbottom" style={{position: 'fixed', bottom: '30px', right: '30px'}}><button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#chatterbox1" aria-controls="offcanvasRight"><ChatIcon style={{width: '40px', height: '40px'}} /></button></div>
        </div>
      )
	}
}

export default Messenger
