import React from 'react';
import firebase from 'firebase/app';
import Recent from './Recent.js';
import {ReactComponent as Arrow} from './icons/arrow-right-circle.svg';
class HomeTripSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      tripno: '',
      advisory: '',
      triperror: '',
			loadingRecent: true,
			loadingSearch: false,
			recent: []
    };
    this.dbRef = firebase.database();
		this.userid = firebase.auth().currentUser.uid;
	}
	componentDidMount() {
    this.dbRef.ref('advisory').on("value", function(snapshot) {
      var adv = snapshot.val();
        this.setState({advisory: adv});
    }.bind(this));
    var go = this.checkURLHash.bind(this);
    //go();

		this.dbRef.ref('users/' + this.userid + '/recent').on('child_added', function(snapshot) {
				this.setState({loadingRecent: false});
	      var tripn = {};
	      tripn.keyer = snapshot.key;
	      tripn.tripno = snapshot.val();
	      var searches = this.state.recent;
	      if (searches.length > 2)
	      {

	        // TO DO:: delete the oldest trip, need to store push ids to do this
	        this.dbRef.ref('users/' + this.userid + '/recent/' + searches[0].keyer).remove();
	        //searches.splice(0, 1);
	        searches.push(tripn);
	        this.setState({recent: searches});
	      }
	      else {
	        searches.push(tripn);
	        this.setState({recent: searches});
	      }

	  }.bind(this));

	  this.dbRef.ref('users/' + this.userid + '/recent').on('child_removed', function(snapshot) {
				this.setState({loadingRecent: false});
	      var tripno = snapshot.val();
	      var searches = this.state.recent;
	      searches.forEach(function(recent, idx) {
	        if (recent.tripno == tripno)
	        {
	          searches.splice(idx, 1);
	        }
	      });
	      this.setState({recent: searches});

	  }.bind(this));

  }
	componentWillUnmount() {
		this.dbRef.ref('users/' + this.userid + '/recent').off();
	}
  submitClick(e) {
    //console.log("url hash ran this");
		this.setState({loadingSearch: true});
    if (e)
    {
      e.preventDefault();
    }

    // get the data from the form
    var tripno = this.state.tripno.toUpperCase();
    // check if valid before chanign state
    this.dbRef.ref('active/' + tripno + '/status').once('value').then(function(snapshot) {
				this.setState({loadingSearch: false});
      if (snapshot.exists())
      {
         this.setState({
            tripno: tripno,
          });
					var already = false;
          this.state.recent.forEach(function(search, idx) {
            if (search.tripno == tripno)
            {
              already = true;
            }
          });
          if (!already)
          {
            this.dbRef.ref('users/' + this.userid + '/recent').push(tripno);
          }

          this.props.onSubmitClick(tripno);
      }
      else
      {
        // do nothing, trip doesn't exist
        this.setState({triperror: "Sorry, that trip doesn't exist or the status has been removed. If you believe this is an error, please call 734-459-5980"});
      }
    }.bind(this));

  }
  onInputChange(e) {
    this.setState({tripno: e.target.value});
  }
 checkURLHash() {
    var idFromHash = Number(window.location.hash.slice(1));
    //var hashlengh = new String(window.location.hash).length;
    console.log(idFromHash);
    if (idFromHash != '' && idFromHash != 0 && idFromHash != undefined)
    {
       this.setState({tripno: idFromHash});
   var go2 = this.submitClick.bind(this);
   go2();
    }

  }
	onRecentClick(tripno)
	{

		this.props.onRecentClick(tripno);
	}
onGetTiming(e)
{
	e.preventDefault();
	this.props.onGetTiming();
}

	render() {
		const listOfRecent = this.state.recent.map(function(tripobj, idx) {
							return (
							 <div id={tripobj.keyer} className="" key={idx} style={{padding: '14px', marginBottom: '5px', marginTop: '5px', cursor: 'pointer'}} onClick={this.onRecentClick.bind(this, tripobj.tripno)}>
							 {tripobj.tripno}
							 </div>)
						}.bind(this));
    return (
<div className="container-fluid" style={{marginTop: "5%"}}>
<div className="row">
<div className="col-1">
</div>
<div className="col-10">
{this.state.advisory == '' ? '' : <div className="alert alert-primary alert-dismissible fade show" role="alert">
		<button type="button" className="close" data-dismiss="alert" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
{this.state.advisory}</div>}
			<h1>Know your options</h1>
<p className="fs-5 col-md-8">Quickly and easily get a timing estimate for a helicopter immediate delivery right here online, for free, with no obligation to book.</p>

<div className="mb-5">
<a href="#" className="btn btn-primary btn-lg px-4" onClick={this.onGetTiming.bind(this)}>Get Timing <Arrow /></a>
</div>

<hr className="col-3 col-md-2 mb-5" />

<div className="row g-5">
<div className="col-md-4">
	<h2>Track Your Flight</h2>
	<p>Enter a trip number to get real time status updates, real time chat, location, and ETA information about your trip.</p>
	<div className="d-grid gap-2 d-sm-flex justify-content-center">
	<div className="input-group mb-3">
	<input type="text" className="form-control" placeholder="Trip Number" aria-label="trip number" aria-describedby="button-addon1" maxLength="9" onChange={this.onInputChange.bind(this)} value={this.state.tripno} />
	<button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={this.submitClick.bind(this)}>Track <Arrow /></button>
	{this.state.loadingSearch ? <div className="d-flex justify-content-center">
	  <div className="spinner-border" role="status">
	    <span className="visually-hidden">Loading...</span>
	  </div>
	</div> : ''}
	{this.state.triperror ? <div id='tripnoerror' className="alert alert-primary alert-dismissible fade show" role="alert" style={{marginTop: '15px'}}>
	{this.state.triperror}</div> : ''}
	</div></div>
</div>

<div className="col-md-8">
	<h6>Recent Searches</h6>
	<Recent onTrip={this.onRecentClick.bind(this)} />
</div>
</div>
<div className="col-1">
</div>
</div>
</div>

</div>

      )
		}

}
export default HomeTripSearch
