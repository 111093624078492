import React from 'react';
import firebase from 'firebase/app'
import "firebase/auth";
import 'firebase/firestore';
import 'firebase/database';
const google = window.google;

class Recent extends React.Component {
	constructor(props) {
		super(props);
    // get this state from firebase
		this.state = {
			mytrips: [],
			none: true,
			loadingRecent: true
    };
    this.fireRef = firebase.firestore();
		this.dbRef = firebase.database();
		this.userid = firebase.auth().currentUser.uid;
	}
  componentDidMount() {

			this.dbRef.ref('users/' + this.userid + '/recent').once('value').then(snapshot => {
				this.setState({loadingRecent: false});
				if (snapshot.exists())
				{
				var objOfRecent = snapshot.val();
					var listOfRecent = [];
				for (var i in objOfRecent) {
					listOfRecent.unshift(objOfRecent[i]);
				}
			//	console.log(listOfRecent);
				var mytrips = this.state.mytrips;
				listOfRecent.forEach(function(tripno) {
				//	console.log(tripno);
					var newtrip = {};
					newtrip.tripno = tripno;
					this.dbRef.ref('active/' + newtrip.tripno + '/to').once('value').then(snapshot6 => {
						if (snapshot6.exists())
						{
							newtrip.to = snapshot6.val();
						}

				this.dbRef.ref('active/' + newtrip.tripno + '/from').once('value').then(snapshot5 => {
					if (snapshot5.exists())
					{
						newtrip.from = snapshot5.val();
					}

					this.dbRef.ref('active/' + newtrip.tripno + '/status').once('value').then(snapshot3 => {
						this.dbRef.ref('active/' + newtrip.tripno + '/initial/toLiteral').once('value').then(snapshot4 => {
						if(snapshot4.exists())
						{

							var toLiteral = snapshot4.val();
						//	console.log(toLiteral);
							newtrip.toLiteralLat = toLiteral.lat;
							newtrip.toLiteralLng = toLiteral.lng;
								//	this.setState({from: trip.from}); already have this
								//	this.setState({to: trip.to}); already have this

									this.dbRef.ref('active/' + newtrip.tripno + '/initial/fromLiteral').once('value').then(snapshot5 => {
			 						if(snapshot5.exists())
			 						{

			 							var fromLiteral = snapshot5.val();
										newtrip.fromLiteralLat = fromLiteral.lat;
										newtrip.fromLiteralLng = fromLiteral.lng;

										var frommarker = new google.maps.Marker({
								    // The below line is equivalent to writing:
								    // position: new google.maps.LatLng(-34.397, 150.644)
								    position: fromLiteral,
								    //map: window.map,
								  });
										var fromGMapsobj = frommarker.getPosition(); // returns a google maps latlng obj for the compute distance function
										var tomarker = new google.maps.Marker({
										// The below line is equivalent to writing:
										// position: new google.maps.LatLng(-34.397, 150.644)
										position: toLiteral,
										//map: window.map,
									});
										var toGMapsobj = tomarker.getPosition(); // returns a google maps latlng obj for the compute distance function

										var meters = google.maps.geometry.spherical.computeDistanceBetween(fromGMapsobj, toGMapsobj);

										// divide by 2
										var half = meters/2;
										//compute heading
										var hdg = google.maps.geometry.spherical.computeHeading(fromGMapsobj, toGMapsobj);
										// compute offset from from
										var center = google.maps.geometry.spherical.computeOffset(fromGMapsobj, half, hdg);
										var str1 = "https://maps.googleapis.com/maps/api/staticmap?center=" + center.lat() + ',' + center.lng() + "style=feature:administrative%7Celement:all%7Cvisibility:on%7Clightness:33&style=feature:landscape%7Ccolor:0xd1d1d1&style=feature:poi.park%7Celement:geometry%7Ccolor:0xdeecdb&style=feature:poi.business%7Cvisibility:off&style=feature:poi.park%7Celement:labels%7Cvisibility:on%7Clightness:25&style=feature:road%7Clightness:25&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.highway%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.highway%7Celement:labels%7Csaturation:-90%7Clightness:25&style=feature:road.arterial%7visibility:on&style=feature:road.arterial%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.local%7Celement:geometry%7Ccolor:0xffffff&style=feature:transit.line%7Cvisibility:off&style=feature:transit.station%7Cvisibility:off&style=feature:water%7Cvisibility:on%7Ccolor:0xe0f1f9";
										var str2 = "&zoom=6&size=600x300&maptype=roadmap&path=color:0x0000ff|weight:5|" + newtrip.fromLiteralLat + "," + newtrip.fromLiteralLng + "|" + newtrip.toLiteralLat + ',' + newtrip.toLiteralLng + "&key=AIzaSyBAqMDxKiUitcfIX2ZEpnb1-tg-iWJKpD4";
										//newtrip.mapurl = "https://maps.googleapis.com/maps/api/staticmap?center=40.17465,-83.45678&zoom=6&size=600x300&maptype=roadmap&path=color:0x0000ff|weight:5|42.737102,-83.990318|41.749825,-82.987963&key=AIzaSyBAqMDxKiUitcfIX2ZEpnb1-tg-iWJKpD4"
										newtrip.mapurl = str1 + str2;
										//console.log(status);
										var status = snapshot3.val();
											//  console.log(status);
										var status2;
										var status3;
											if(status == 'Pre-departure Preparations')
							 {
								status2 = '1';
								status3 = 'its';
							 }
							 if (status == 'Van Enroute to Pickup')
							 {
								status = "Truck Enroute to Pickup";
								status2 = '2';
								status3 = 'its';
							 }
							 if (status == 'Van Waiting On Freight')
							 {
								status = "Truck Waiting on Freight";
								status2 = '2';

							 }
							 if (status == 'Van Loading' )
							 {
									status = "Truck Loading";
									status2 = '3';

							 }
							 if (status == 'Van Enroute to Aircraft')
							 {
								status = "Truck Enroute to Aircraft";
								status2 = '2';
								status3 = 'itp';
							 }
								if (status == 'Van Enroute to Plant')
							 {
								status = "Truck Enroute to Delivery";
								status2 = '4';
								status3 = 'itp';
							 }
								if (status == 'Van On Site')
							 {
								status = "Truck On Site";
								status2 = '2';
								status3 = 'its';
							 }
								if (status == 'Van Waiting On Paperwork')
							 {
								status = "Truck Waiting On Paperwork";
								status2 = '2';
								status3 = 'its';
							 }
								if (status == 'Van Waiting for Offload')
							 {
								status = "Truck Waiting For Offload";
								status2 = '4';
								status3 = 'itp';
							 }
							 if (status == 'Aircraft Enroute to Pickup')
							 {
								status2 = '2';
								status3 = 'its';
							 }
								if (status == 'Aircraft Waiting On Freight')
							 {
								status2 = '2';
								status3 = 'itp';
							 }
							 if (status == 'Aircraft Loading')
							 {
								status2 = '3';
								status3 = 'itp';
							 }
								 if (status == 'Aircraft Clearing Customs Outbound')
							 {
								status2 = '3';

							 }
							 if (status == 'Aircraft Enroute to Plant')
							 {
								status = "Aircraft Enroute to Delivery";
								status2 = '4';
								status3 = 'itp';
							 }
									if (status == 'Aircraft Clearing Customs Inbound')
							 {
								status2 = '4';
							 }
							 if (status == 'Freight Delivered to Third Party')
							 {
								status2 = '5';
							 }
								if (status == 'Aircraft 10 mins Out')
							 {
								status2 = '4';
							 }
								if (status == 'Trip Cancelled')
							 {
								status2 = '5'
							 }
								if (status == 'Freight Delivered')
							 {
								status2 = '5';
							 }
							newtrip.status = status;
							 newtrip.progress = status2;
							 mytrips.push(newtrip);


							 this.setState({none: false});
						 this.setState({mytrips: mytrips});


			 					 }
			 					 else {
			 						// snapshot doesnt exist (old style trip beofre the new app or failed to enter addresses)

			 					 }
			 					 });


					 }
					 else {
						// snapshot doesnt exist (old style trip beofre the new app or failed to enter addresses)

					 }
					 });


				 });
				 		});
								});


				}.bind(this));
			}
		});
  }

  componentWillUnmount() {

  }
	onTripSelect(tripno, e)
	{
		e.preventDefault();
		this.props.onTrip(tripno);
	}

	render() {
		const listOfTrips = this.state.mytrips.map(function(trip, idx) {
              return (
								<div key={idx} className="card" style={{width: "18rem", float: 'left', marginRight: '10px', marginBottom: '10px', cursor: 'pointer'}} onClick={this.onTripSelect.bind(this, trip.tripno)}>
   <img src={trip.mapurl} className="card-img-top" alt="..." />
   <div className="card-body">
	 		<img src={trip.progress == '1' ? 'https://go.mcmahonnow.com/images/Progress1.png' : trip.progress == '2' ? 'https://go.mcmahonnow.com/images/Progress2.png' : trip.progress == '3' ? 'https://go.mcmahonnow.com/images/Progress3.png' : trip.progress == '4' ? 'https://go.mcmahonnow.com/images/Progress4.png' : trip.progress == '5' ? 'https://go.mcmahonnow.com/images/Progress5.png' : ''} style={{width: "100%"}} />
     <h5 className="card-title">{trip.from} to {trip.to}</h5>
		 <h6 className="card-title">Trip # {trip.tripno}</h6>
		 <p>Status: {trip.status}</p>
     <p className="card-text">{trip.datestring}</p>

   </div>
 </div>)}.bind(this));
    return (


			<div>
			{this.state.none ? <p>No recent trip searches.</p> : <div style={{marginTop: '15px'}}>{listOfTrips}</div>}
			{this.state.loadingSearch ? <div class="d-flex justify-content-center">
			  <div class="spinner-border" role="status">
			    <span class="visually-hidden">Loading...</span>
			  </div>
			</div> : ''}

			</div>


      )
		}

}
export default Recent
