import React from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import {Modal} from 'bootstrap';
import {Accordion} from 'bootstrap';
import $ from "jquery";


class Help extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      trips: [],
      deleteState: ''
    };
		this.dbRef = firebase.database();
     this.storageRef = firebase.storage();
	}

  componentDidMount() {

  }
  componentWillUnmount() {

  }




	render() {

		return (
			<div className="container-fluid">
			<div className="row">
			<div className="col-1">
			</div>
			<div className="col-10" style={{marginTop: "40px"}}>
			<h3>How can we help you today?</h3>
			<div className="accordion" id="accordionExample" style={{marginTop: "30px"}}>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        How do I track a flight?
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>Click on the menu icon in the top left, click on Track, then enter a trip number and click Track.</strong> If you do not know your trip number, please call us at 734-459-5980.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        How do I get a quote for a helicopter freight delivery?
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>If you need an immediate quote including pricing, timing and availability, call us at 734-459-5980.</strong> If you only need timing information for a helicopter delivery right now, click on the menu icon in the top left, then click Get Timing. Enter a pickup and delivery address and fill out the cargo information. Then click Get Timing. A box will display with approximate timing, and you can then call us to book this charter.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        What happens when I click "Get Timing"?
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>This does NOT book a charter.</strong> Get Timing only provides approximate timing information for your flight. To actually dispatch an aircraft, you must call us at 734-459-5980.  </div>
    </div>
  </div>
	<div className="accordion-item">
		<h2 className="accordion-header" id="headingFour">
			<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseTwo">
				What happens when I subscribe to a trip? How do I unsubscribe?
			</button>
		</h2>
		<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
			<div className="accordion-body">
				<strong>Yow will receive email updates on the trip status.</strong> You may unsubscribe by clicking the same button, although this is often unnecessary, as you will not receive anymore status emails after the trip completes.
			</div>
		</div>
	</div>
	<div className="accordion-item">
		<h2 className="accordion-header" id="headingFive">
			<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseTwo">
				How accurate is the timing estimate?
			</button>
		</h2>
		<div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
			<div className="accordion-body">
				<strong>The timing tool estimate is based on a number of assumptions, and there are many reasons the actual delivery timing could differ significantly from the estimate, but the tool is designed to provide realistic, base case scenario timings, not overly optimistic or best case scenario timings. </strong> The timing tool does not take into account wind, weather or other delays, mechanical problems, delays related to the freight not being ready or ground transportation delays, etc. In other words, it is possible the actual timing could be quicker or slower than the estimated timing.
			</div>
		</div>
	</div>
	<div className="accordion-item">
		<h2 className="accordion-header" id="headingSix">
			<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseTwo">
				How do I know if a helicopter can land at my pickup or delivery addresses?
			</button>
		</h2>
		<div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
			<div className="accordion-body">
				<strong>You do not need to know this to get timing. If in doubt, just check No and call us.</strong> If you decide to book the charter, call us at 734-459-5980 and a pilot or dispatcher will examine the satellite photo of your addresses and ask other questions about the landing area to make the determination before sending an aircraft.
			</div>
		</div>
	</div>
	<div className="accordion-item">
		<h2 className="accordion-header" id="headingSeven">
			<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseTwo">
				How do I get help on something that is not listed here?
			</button>
		</h2>
		<div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
			<div className="accordion-body">
				<strong>Contact us via email for non-urgent matters.</strong> We are available 24/7 for freight emergencies.
			</div>
		</div>
	</div>
	<div className="accordion-item">
		<h2 className="accordion-header" id="headingEight">
			<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseTwo">
				How do I edit or delete my account?
			</button>
		</h2>
		<div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
			<div className="accordion-body">
				<strong>Click the avatar icon in the upper right.</strong> On the My Account page you can change all of your information. For further assistance, contact us via email.
			</div>
		</div>
	</div>
</div>
</div>
<div className="row">
	<div className="col-3">
	</div>
	<div className="col-6 text-center" style={{marginTop: "60px"}}>
		<h4>Still need help?</h4>
		<h5>Call us:</h5>
		<h6>1-800-752-8590 (toll-free)</h6>
		<h6>1-734-459-5980 (direct)</h6>
		<h6>sales@mhsheli.com (email)</h6>
	</div>
	<div className="col-3">
	</div>
</div>
<p></p>
</div>
<div className="col-1">
</div>
</div>
      )
	}
}

export default Help
