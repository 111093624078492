import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import $ from "jquery";
import TrackMap from './TrackMap';
import Messenger from './Messenger';
import Progress1 from './Progress1.png';
import Progress2 from './Progress2.png';
import Progress3 from './Progress3.png';
import Progress4 from './Progress4.png';
import progress5 from './Progress5.png';
import {ReactComponent as Arrow} from './icons/arrow-right-circle.svg';
import {Modal} from 'bootstrap';

class CustomerTrip2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

      status: '',
			statusForMapLine: 'its',
      eta: '',
      subscribed: false,
      statuslog: [],
      progress: '',
      displayMap: true,
      showReloading: false,
			pickupLiteral: {},
			deliveryLiteral: {},
      pod: ''
    };
      this.dbRef = firebase.database();
			this.fireRef = firebase.firestore();
			this.userid = firebase.auth().currentUser.uid;
	}
  componentDidMount() {
    var st = this.startTimer.bind(this, this.props.tripno);
      st();
      var stamp = new Date();
    var tm = stamp.getTime();
    var tm2 = tm + '';
       this.dbRef.ref('active/' + this.props.tripno + '/initial/req').set(tm2);
    // subscribe to firebase and set status and eta from it
    this.dbRef.ref('active/' + this.props.tripno + '/status').on('value', function(snapshot3) {

        //console.log(status);
        var status = snapshot3.val();
          //  console.log(status);
        var status2;
				var status3 = this.state.status3;
          if(status == 'Pre-departure Preparations')
   {
    status2 = '1';
		status3 = 'its';
   }
   if (status == 'Van Enroute to Pickup')
   {
    status = "Truck Enroute to Pickup";
    status2 = '2';
		status3 = 'its';
   }
   if (status == 'Van Waiting On Freight')
   {
    status = "Truck Waiting on Freight";
    status2 = '2';
		status3 = 'itp';
   }
   if (status == 'Van Loading' )
   {
      status = "Truck Loading";
      status2 = '3';
			status3 = 'itp';
   }
   if (status == 'Van Enroute to Aircraft')
   {
    status = "Truck Enroute to Aircraft";
    status2 = '2';
		status3 = 'itp';
   }
    if (status == 'Van Enroute to Plant')
   {
    status = "Truck Enroute to Delivery";
    status2 = '4';
		status3 = 'itp';
   }
    if (status == 'Van On Site')
   {
    status = "Truck On Site";
    status2 = '2';
		status3 = 'its';
   }
    if (status == 'Van Waiting On Paperwork')
   {
    status = "Truck Waiting On Paperwork";
    status2 = '2';
		status3 = 'its';
   }
    if (status == 'Van Waiting for Offload')
   {
    status = "Truck Waiting For Offload";
    status2 = '4';
		status3 = 'itp';
   }
   if (status == 'Aircraft Enroute to Pickup')
   {
    status2 = '2';
		status3 = 'its';
   }
    if (status == 'Aircraft Waiting On Freight')
   {
    status2 = '2';
		status3 = 'itp';
   }
   if (status == 'Aircraft Waiting On Paperwork')
   {
    status2 = '2';
		status3 = 'itp';
   }
   if (status == 'Aircraft Loading')
   {
    status2 = '3';
		status3 = 'itp';
   }
     if (status == 'Aircraft Clearing Customs Outbound')
   {
    status2 = '3';

   }
   if (status == 'Aircraft Enroute to Plant')
   {
    status = "Aircraft Enroute to Delivery";
    status2 = '4';
		status3 = 'itp';
   }
      if (status == 'Aircraft Clearing Customs Inbound')
   {
    status2 = '4';
   }
   if (status == 'Freight Delivered to Third Party')
   {
    status2 = '5';
		status3 = 'delivered';
   }
    if (status == 'Aircraft 10 mins Out')
   {
    status2 = '4';
		status3 = 'itp';
   }
    if (status == 'Trip Cancelled')
   {
    status2 = '5'
		status3 = 'its';
   }
    if (status == 'Freight Delivered')
   {
    status2 = '5';
		status3 = 'delivered';
   }
    this.setState({status: status});
   this.setState({progress: status2});
	 this.setState({statusForMapLine: status3});

    }.bind(this));

     this.dbRef.ref('active/' + this.props.tripno + '/eta').on('value', function(snapshot5) {
       this.setState({eta: snapshot5.val()});
       //console.log('eta:' + snapshot3.val());
    }.bind(this));
    this.dbRef.ref('active/' + this.props.tripno + '/pod').on('value', function(snapshot4) {
      if(snapshot4.exists())
      {
      var beginning = snapshot4.val().substring(0,62);
if (beginning === 'https://storage.googleapis.com/mhsnow-b89d3.appspot.com/trips/')
{
  this.setState({pod: snapshot4.val()});
}
}
      //console.log('eta:' + snapshot3.val());
   }.bind(this));

    /*
     this.dbRef.ref('active/' + this.props.tripno).on('child_added', function(snapshot3) {
     if (snapshot3.key == 'eta')
      {
        this.setState({eta: snapshot3.val()});
      }
      if (snapshot3.key == 'status')
      {
        this.setState({status: snapshot3.val()});
        var status = snapshot3.val();
        var status2;
          if(status == 'Pre-departure Preparations')
   {
    status2 = '1';
   }
   if (status == 'Van Enroute to Pickup')
   {
    status2 = '2';
   }
   if (status == 'Van Waiting On Freight')
   {
    status2 = '2';
   }
   if (status == 'Van Loading' )
   {
      status2 = '2';
   }
   if (status == 'Van Enroute to Aircraft')
   {
    status2 = '3';
   }
   if (status == 'Aircraft Enroute to Pickup')
   {
    status2 = '3';
   }
    if (status == 'Aircraft Waiting On Freight')
   {
    status2 = '3';
   }
   if (status == 'Aircraft Loading')
   {
    status2 = '3';
   }
   if (status == 'Aircraft Enroute to Plant')
   {
    status2 = '4';
   }
   if (status == 'Freight Delivered to Third Party')
   {
    status2 = '5';
   }
    if (status == 'Aircraft 10 mins Out')
   {
    status2 = '4';
   }
    if (status == 'Trip Cancelled')
   {
    status2 = '5'
   }
    if (status == 'Freight Delivered')
   {
    status2 = '5';
   }
   this.setState({progress: status2});
      }
    }.bind(this));

    this.dbRef.ref('active/' + this.props.tripno).on('child_changed', function(snapshot2) {
      if (snapshot2.key == 'eta')
      {
        this.setState({eta: snapshot2.val()});
      }
      if (snapshot2.key == 'status')
      {
        this.setState({status: snapshot2.val()});
        var status = snapshot2.val();
        var status2;
          if(status == 'Pre-departure Preparations')
   {
    status2 = '1';
   }
   if (status == 'Van Enroute to Pickup')
   {
    status2 = '2';
   }
   if (status == 'Van Waiting On Freight')
   {
    status2 = '2';
   }
   if (status == 'Van Loading' )
   {
      status2 = '2';
   }
   if (status == 'Van Enroute to Aircraft')
   {
    status2 = '3';
   }
   if (status == 'Aircraft Enroute to Pickup')
   {
    status2 = '3';
   }
    if (status == 'Aircraft Waiting On Freight')
   {
    status2 = '3';
   }
   if (status == 'Aircraft Loading')
   {
    status2 = '3';
   }
   if (status == 'Aircraft Enroute to Plant')
   {
    status2 = '4';
   }
   if (status == 'Freight Delivered to Third Party')
   {
    status2 = '5';
   }
    if (status == 'Aircraft 10 mins Out')
   {
    status2 = '4';
   }
    if (status == 'Trip Cancelled')
   {
    status2 = '5'
   }
    if (status == 'Freight Delivered')
   {
    status2 = '5';
   }
   this.setState({progress: status2});
      }
    }.bind(this));
*/
    // check to see if they're subscribed to set subscription status (button state) properly
    var userid = firebase.auth().currentUser.uid;
    this.dbRef.ref('users/' + userid + '/my_subscriptions').on('child_added', function(snapshot3) {
      //  console.log(snapshot3.key);
        if (snapshot3.key == this.props.tripno)
        {
          this.setState({subscribed: true});
        }
    }.bind(this));
     this.dbRef.ref('users/' + userid + '/my_subscriptions').on('child_removed', function(snapshot3) {
      if (snapshot3.key == this.props.tripno)
      {
        this.setState({subscribed: false});
      }
    }.bind(this));
this.dbRef.ref('active/' + this.props.tripno + '/status_log').orderByChild('timestamp').on('child_added', function(snapshot9) {
    var newloglist = this.state.statuslog;
    var statusobj = {};
    statusobj.id = snapshot9.key;
    statusobj.content = snapshot9.val().content;
    var tconv = Number(snapshot9.val().timestamp);
     var ds = new Date(tconv);
    var time = ds.getHours();
   var time2 = ds.getMinutes();
   if (time2 < 10)
   {
    time2 = '0' + time2;
   }
    statusobj.timestamp = ds.toDateString() + ' ' + time + ':' + time2;

    newloglist.unshift(statusobj);

    this.setState({statuslog: newloglist});
}.bind(this));

this.dbRef.ref('active/' + this.props.tripno + '/status_log').on('child_removed', function(snapshot10) {
    var newloglist = this.state.statuslog;

    newloglist.forEach(function(value, ind)
    {
      if (value.id == snapshot10.key)
      {
        newloglist.splice(ind, 1);
      }
    });


    this.setState({statuslog: newloglist});
}.bind(this));


// increment the customer viewer counter
this.dbRef.ref('active/' + this.props.tripno + '/viewers/' + userid).set('1');
this.timingModal = new Modal(document.getElementById('timingModal'), {
  keyboard: true
});

    $(document).scrollTop(0);


  }
  startTimer(tripno) {
    // every 2 minutes
    this.str = setInterval(function() {
      var stamp = new Date();
    var tm = stamp.getTime();
    var tm2 = tm + '';
   this.dbRef.ref('active/' + tripno + '/initial/req').set(tm2);
 }.bind(this), 121000);
    //console.log('str:' + this.str);
  }
  stopTimer() {
    clearInterval(this.str);
  }

  componentDidUpdate() {
  //  console.log("customer trip updated");
  }
   componentWillUnmount() {
  //  console.log('component unmounted');
    //this.stopTimer.bind(this);
    this.timingModal.dispose();

    // decrement the viewers list by removing our user id node
this.dbRef.ref('active/' + this.props.tripno + '/viewers/' + this.userid).set(null);
  this.dbRef.ref('users/' + this.userid + '/my_subscriptions').off();

// unsubscribe
    this.dbRef.ref('active/' + this.props.tripno + '/eta').off();
    this.dbRef.ref('active/' + this.props.tripno + '/pod').off();
     this.dbRef.ref('active/' + this.props.tripno + '/status').off();

    this.dbRef.ref('active/' + this.props.tripno + '/status_log').off();
    //this.stopTimer.bind(this);
  //  console.log(this.str);
    clearInterval(this.str);
  }
  onBack(e) {
    e.preventDefault();
    this.props.onBack();
  }
  onSubscribe(e) {
    e.preventDefault();
    var userid = firebase.auth().currentUser.uid;
    var newref = this.dbRef.ref('active/' + this.props.tripno + '/subscribers').push({uid: userid});
    var newid = newref.toString();
    var newpush = newid.slice(-20);
    this.dbRef.ref('users/' + userid + '/my_subscriptions/' + this.props.tripno).set(newpush);
  }
  onUnsubscribe(e) {
    e.preventDefault();
    var userid = firebase.auth().currentUser.uid;
    this.dbRef.ref('users/' + userid + '/my_subscriptions/' + this.props.tripno).once('value').then((snapshot) => {
      var pushid = snapshot.val();
      this.dbRef.ref('active/' + this.props.tripno + '/subscribers/' + pushid).set(null);
      this.dbRef.ref('users/' + userid + '/my_subscriptions/' + this.props.tripno).set(null);
    });
  }

  onTermsClick(e)
  {
    e.preventDefault();
  //  $('#exampleModal').modal('show');
  }
  onMapReload() {
      this.setState({displayMap: false});
      this.setState({showReloading: true});
    window.setTimeout(function() {
      this.setState({displayMap: true});
      this.setState({showReloading: false});
    }.bind(this), 3000);
  }
  showPOD() {
    this.timingModal.show();
  }
	render() {
    const listOfStatuses = this.state.statuslog.map(function(status, idx) {
              return (
               <div id={status.id} className="list-group statusli" key={idx} style={{backgroundColor: '#ededed', borderRadius: '5px', padding: '14px', marginBottom: '5px', marginTop: '5px'}}>
                  <div>{status.content}</div>
                  <small>{status.timestamp}</small>
               </div>)
            }.bind(this));
    return (
			<div className="container-fluid pb-3" style={{marginTop: '10px'}}>
			<div className="row">

					<div className="col-md-6 col-lg-4">
					<div className="bg-light border rounded-3" id="form-outline" style={{padding: "20px"}}>

          <div className="tripno"><h1>Trip #{this.props.tripno}</h1></div>
          <div className="tripno">
            <h5>Subscribe to This Trip</h5>
            {this.state.subscribed ? <button className='btn btn-primary' onClick={this.onUnsubscribe.bind(this)}>Unsubscribe</button> : <button className='btn btn-primary' onClick={this.onSubscribe.bind(this)}>Get Email Updates</button>}
            <div id='advice' style={{marginTop: '10px'}}><p id="smaller">Click the above to get email updates whenever the status of this trip changes. Clicking the above signifies agreement with our <a href="#" data-toggle="modal" data-target="exampleModal" onClick={this.onTermsClick.bind(this)}>Terms and Conditions</a>. You may unsubscribe at anytime.</p></div>
            <hr />
            <h3>ETA: {this.state.eta} ET</h3>
            <img src={this.state.progress == '1' ? 'https://go.mcmahonnow.com/images/Progress1.png' : this.state.progress == '2' ? 'https://go.mcmahonnow.com/images/Progress2.png' : this.state.progress == '3' ? 'https://go.mcmahonnow.com/images/Progress3.png' : this.state.progress == '4' ? 'https://go.mcmahonnow.com/images/Progress4.png' : this.state.progress == '5' ? 'https://go.mcmahonnow.com/images/Progress5.png' : ''} style={{width: "100%"}} />

            <div style={{marginBottom: '5px'}}>Status: {this.state.status}</div>
      {this.state.pod ? <button type="button" class="btn btn-outline-dark" onClick={this.showPOD.bind(this)}>View POD <Arrow /></button> : ''}
            <hr />
            <h6>Trip History</h6>
            <div id="statusloglist" style={{minHeight: '400px'}}>{listOfStatuses}</div>
            </div>
          <hr />

</div>
</div>



					<div id="size-target" className="col-md-6 col-lg-8">
	      <div className="bg-light border rounded-3">
				<TrackMap tripno={this.props.tripno} showIt={false} pickup={this.state.pickupLiteral} delivery={this.state.deliveryLiteral} status={this.state.statusForMapLine} /><Messenger tripid={this.props.tripno} />
	      </div>
	</div>


          </div>

          <div className="modal fade" id="timingModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Proof Of Delivery</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
			<img src={this.state.pod} width='100%' />
			
				

      </div>

    </div>
  </div>
</div>




      </div>
      )
		}

}
export default CustomerTrip2
