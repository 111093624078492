import React from 'react';
import firebase from 'firebase/app';
import $ from "jquery";
import {Dropdown} from 'bootstrap';
import {Offcanvas} from 'bootstrap';
import {ReactComponent as Alarm} from './icons/stopwatch.svg';
import {ReactComponent as Pin} from './icons/geo-alt-fill.svg';
import {ReactComponent as List} from './icons/list.svg';
import {ReactComponent as Bars} from './icons/reception-4.svg';
import logo from './freightoptions.png';
import logowhite from './mcmahonlogowhite.png';
import logofo from './freightoptions.png';

class Navbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      smallscreen: false
    };
    this.checkScreenSize();
	}
	checkScreenSize() {
    // if small, change state so links appear in dropdown
    var w = window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth;
/*
var h = window.innerHeight
|| document.documentElement.clientHeight
|| document.body.clientHeight;*/
  if (w < 769)
  {
    this.setState({smallscreen: true});
  }
  }
	componentDidMount() {

    //go();
		var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    this.dropdown = new Dropdown(dropdownToggleEl);
}.bind(this));
var offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'))
var offcanvasList = offcanvasElementList.map(function (offcanvasEl) {
  this.offcanvas = new Offcanvas(offcanvasEl);
}.bind(this));
  }
	componentWillUnmount() {

	this.dropdown.dispose();
	this.offcanvas.dispose();
	}
  logout(e) {
    e.preventDefault();
    firebase.auth().signOut().then(function() {
  // Sign-out successful.
  this.props.onLogout();
}.bind(this)).catch(function(error) {
  // An error happened.
    var errorMessage = error.message;
  console.log(errorMessage);
});

  }
  viewChange2(news, e) {
    e.preventDefault();

	this.dropdown.toggle();

    this.props.onLinkClick(news);
  }
	viewChange(news, e) {
    e.preventDefault();

		 this.offcanvas.hide();

    this.props.onLinkClick(news);
  }
	render() {
    return (
			<header className="p-3 border-bottom">
	 <div className="container-fluid">
		 <div className="align-items-center justify-content-center justify-content-lg-start" style={{marginTop: "12px"}}>
		 <button className="btn btn-outline-dark" type="button" style={{marginBottom: "12px"}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
<List />
</button>
			 <a href="/" className="align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
			 <img src={logofo} alt="Logo" width="150px" className="d-none d-lg-inline" style={{marginTop: "-25px", marginLeft: "25px"}} />
			 <img src={logo} alt="Logo" width="150px" className="d-xs-inline d-lg-none" style={{marginTop: "-15px", marginLeft: "25px"}} />
			 </a>





			 <div className="dropdown text-end" style={{display: 'inline', float: 'right'}}>
				 <a href="#" className="link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
					 <img src="https://go.mcmahonnow.com/images/avatar.png" alt="avatar" width="32" height="32" className="rounded-circle" />
				 </a>
				 <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
				 <li><a className="dropdown-item" href="/privacy.html" target="_blank" rel="noopener">Privacy</a></li>
				 <li><a className="dropdown-item" href="/terms.html" target="_blank" rel="noopener">Terms</a></li>
				<li><a className="dropdown-item" href="#" onClick={this.viewChange2.bind(this, 'help')}>Help</a></li>
				 <li><a className="dropdown-item" href="#" onClick={this.viewChange2.bind(this, 'account')}>Account</a></li>
				 <li><hr className="dropdown-divider" /></li>
				 <li><a className="dropdown-item" href="#" onClick={this.logout.bind(this)}>Sign out</a></li>
				 </ul>
			 </div>
		 </div>
	 </div>
	 <div className="offcanvas offcanvas-start text-white bg-dark" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div className="offcanvas-header">
    <img src={logowhite} alt="Logo" width="150px" style={{}}/>
    <button type="button" className="btn-close text-reset btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" style={{marginTop: "4px"}}></button>
  </div>
  <div className="offcanvas-body">
	<div className="p-3 text-white bg-dark">

<hr />
<ul className="nav nav-pills flex-column mb-auto">

	<li>
		<a href="#" className={this.props.activeLink == 'timing' ? "nav-link active" : "nav-link text-white"}  onClick={this.viewChange.bind(this, 'timing')}>
			<Alarm style={{marginRight: "7px"}} />
			Get Timing
		</a>
	</li>
	<li>
		<a href="#" className={this.props.activeLink == 'home' ? "nav-link active" : "nav-link text-white"} onClick={this.viewChange.bind(this, 'home')}>
			<Pin style={{marginRight: "7px"}} />
			Track
		</a>
	</li>
	<li>
		<a href="#" className={this.props.activeLink == 'home' ? "nav-link active" : "nav-link text-white"} onClick={this.viewChange.bind(this, 'mytrips')}>
			<List style={{marginRight: "7px"}} />
			My Trips
		</a>
	</li>



</ul>
<hr />

</div>
  </div>
</div>
 </header>

      )
		}
}

export default Navbar
