import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import {Modal} from 'bootstrap';
import {Dropdown} from 'bootstrap';
import Navbar from './Navbar.js';
import TimingMap from './TimingMap.js';
const google = window.google;

class Timing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			from: '', // google address string for pickup
			to: '', //google address string for delivery
			pickupState: '',
			deliveryState: '',
			pickupCity: '',
			deliveryCity: '',
			pickupCompany: '', //pickup company name
			deliveryCompany: '', //delivery company name
			fromLiteral: false, // lat lng literal of from locatio, itially set false for vakidation code
			toLiteral: false, // lat lng literal of to location
			fromLZLiteral: {},// lat lng literal of helicopter pickup (may be same as fromliteral if landFrom is true)
			toLZLiteral: {},  // same as above
			width: '',
			height: '',
			length: '',
			qty: '1',
			weight: '',
			container: '',
			landTo: true,
			landFrom: true,
			facilities: [], // not used anymore, for testing only
			submitted: false,
			type: 'air', // not used anymore
			pickupLandHere: true,
			pickupLandHereYes: 'checked',
			pickupLandHereNo: false,
			pickupLandHereMaybe: false,
			deliveryLandHere: true,
			deliveryLandHereYes: 'checked',
			deliveryLandHereNo: false,
			deliveryLandHereMaybe: false,
			pickupBeenHere: false,
			pickupBeenHereYes: false,
			pickupBeenHereNo: 'checked',
			pickupBeenHereMaybe: false,
			deliveryBeenHere: false,
			deliveryBeenHereYes: false,
			deliveryBeenHereNo: 'checked',
			deliveryBeenHereMaybe: false,
			brokenDown: false,
			brokenDownYes: false,
			brokenDownNo: 'checked',
			brokenDownMaybe: false,
			readyNow: true,
			readyNowTrue: true,
			readyNowFalse: false,
			datestring: '',
			timestring: '',
			fromStreetNumber: '',
			toStreetNumber: '',
			author: '',
			dispatch: '',
			leg1: '',
			leg2: '',
			fuel: '',
			load: '',
			displayETE: '',
			fromStreetName: '',
			toStreetName: '',
			fromPostal: 0,
			toPostal: 0,
			returnTime: 0,
			initialHours: 0,
			initialMins: 0,
			initialSeconds: 0,
			loadingTiming: false,
			customs: '',
			refnumber: '',
			requestorCompany: '',
			needUsername: false,
			name: '',
			needCompany: false
    };
    this.dbRef = firebase.database();
		this.fireRef = firebase.firestore();
		this.uid = firebase.auth().currentUser.uid;
		this.username = firebase.auth().currentUser.displayName;
	}
  componentDidMount() {
		if (this.username == undefined || this.username == '' || this.username == null || this.username.length < 1)
		{
			this.setState({needUsername: true});
		}
		else {
			this.setState({needUsername: false});
		}
		this.dbRef.ref('users/' + this.uid + '/company').once('value').then(function(snapshot) {
			if(snapshot.exists())
			{
				var uc = snapshot.val();
				this.setState({requestorCompany: uc});
				this.setState({needCompany: false});
			}
			else {
				this.setState({needCompany: true});
			}

		}.bind(this)).catch(error => {});
		this.timingModal = new Modal(document.getElementById('timingModal'), {
  keyboard: true
});
		var defaultBounds = new google.maps.LatLngBounds(
		new google.maps.LatLng(35.067445, -93.409835),
		new google.maps.LatLng(44.192607, -79.00041)
		);

		var input = document.getElementById('from');
		var options = {
		bounds: defaultBounds,
		types: ['establishment', 'geocode']
		};

		this.autocomplete = new google.maps.places.Autocomplete(input, options);

		this.aclsr = this.autocomplete.addListener('place_changed', this.getFromLatLng.bind(this));


		var input2 = document.getElementById('to');
		var options2 = {
		bounds: defaultBounds,
		types: ['address']
		};

		this.autocomplete2 = new google.maps.places.Autocomplete(input2, options2);

		this.ac2lsr = this.autocomplete2.addListener('place_changed', this.getToLatLng.bind(this));

		this.geocoder = new google.maps.Geocoder();




  }
	componentWillUnmount() {
	//	this.autocomplete2.removeListener('place_changed', this.getToLatLng());
		//	this.autocomplete.removeListener('place_changed', this.getFromLatLng());
		google.maps.event.removeListener(this.ac2lsr);
		google.maps.event.clearInstanceListeners(this.autocomplete);
		google.maps.event.removeListener(this.aclsr);
		google.maps.event.clearInstanceListeners(this.autocomplete2);
		this.timingModal.dispose();
	}
	getFromLatLng()
	{
		// only called if props.type == ground
	 var address = document.getElementById('from').value;
	 this.setState({from: address});
	 //var address = this.state.fromVal;
	 // get the individual components
	 var place = this.autocomplete.getPlace();
	 var address1 = "";
	 this.setState({fromStreetNumber: ''});
	 for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address1 = `${component.long_name}`;
				this.setState({fromStreetNumber: address1});

        break;
      }

      case "route": {
        //address1 += component.short_name;
				this.setState({fromStreetName: component.short_name});
        break;
      }

      case "postal_code": {
      //  postcode = `${component.long_name}${postcode}`;
			var postcode2 = `${component.long_name}`;
			this.setState({fromPostal: postcode2});
        break;
      }
/*
      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }*/
      case "locality":
      //  document.querySelector("#locality").value = component.long_name;
			this.setState({pickupCity: component.long_name});
        break;

      case "administrative_area_level_1": {
      //  document.querySelector("#state").value = component.short_name;
				this.setState({pickupState: component.short_name});
        break;
      }
      case "country":
        this.setState({pickupCountry: component.long_name});
        break;
    }
  }
	 this.geocoder.geocode( { 'address': address}, function(results, status) {
		 if (status == 'OK') {
			 //map.setCenter(results[0].geometry.location);
			 /*var marker = new google.maps.Marker({
					 map: map,
					 position: results[0].geometry.location
			 });*/
			 this.setState({fromLiteral: results[0].geometry.location});
			 console.log(results[0].geometry.location);
		 } else {
			 //alert('Geocode was not successful for the following reason: ' + status);
			 this.setState({fromLiteral: false});
		 }
	 }.bind(this));

	}
	getToLatLng()
	{
		var address = document.getElementById('to').value;
		//var address = this.state.toVal;
			 this.setState({to: address});
			 var place = this.autocomplete2.getPlace();
			 var address1 = "";
			  this.setState({toStreetNumber: ''});
			 for (const component of place.address_components) {
		    const componentType = component.types[0];

		    switch (componentType) {
		      case "street_number": {
		        address1 = `${component.long_name}`;
						this.setState({toStreetNumber: address1});
		        break;
		      }

		      case "route": {
		       // address1 += component.short_name;
					 this.setState({toStreetName: component.short_name});
		        break;
		      }

		      case "postal_code": {
		        var postcode = `${component.long_name}`;
						this.setState({toPostal: postcode});
		        break;
		      }
/*
		      case "postal_code_suffix": {
		        postcode = `${postcode}-${component.long_name}`;
		        break;
		      }*/
		      case "locality":
		      //  document.querySelector("#locality").value = component.long_name;
					this.setState({deliveryCity: component.long_name});
		        break;

		      case "administrative_area_level_1": {
		      //  document.querySelector("#state").value = component.short_name;
						this.setState({deliveryState: component.short_name});
		        break;
		      }
		      case "country":
		        this.setState({deliveryCountry: component.long_name});
		        break;
		    }
		  }
		this.geocoder.geocode( { 'address': address}, function(results, status) {
			if (status == 'OK') {
				//map.setCenter(results[0].geometry.location);
				/*var marker = new google.maps.Marker({
						map: map,
						position: results[0].geometry.location
				});*/
				this.setState({toLiteral: results[0].geometry.location});
				console.log(results[0].geometry.location);
			} else {
				//console.log('Geocode was not successful for the following reason: ' + status);
				this.setState({toLiteral: false});
			}
		}.bind(this));
	}
onPickupCompanyChange(e)
{
	e.preventDefault();
	this.setState({pickupCompany: e.target.value});
}
onDeliveryCompanyChange(e)
{
	e.preventDefault();
	this.setState({deliveryCompany: e.target.value});
}
onQtyChange(e)
{
	e.preventDefault();
	this.setState({qty: e.target.value});
}
onWeightChange(e)
{
	e.preventDefault();
	this.setState({weight: e.target.value});
}
	onPickupLandHere(e)
	{
		//e.preventDefault();
		var plh = e.target.value;
		if (plh == 'yes')
		{
				this.setState({pickupLandHere: true});
				this.setState({pickupLandHereYes: true});
				this.setState({pickupLandHereNo: false});
				this.setState({pickupLandHereMaybe: false});
		}
		else if (plh == 'maybe')
		{
			this.setState({pickupLandHere: true});
			this.setState({pickupLandHereYes: false});
			this.setState({pickupLandHereNo: false});
			this.setState({pickupLandHereMaybe: true});

		}
		else {
			this.setState({pickupLandHere: false});
			this.setState({pickupLandHereYes: false});
			this.setState({pickupLandHereNo: true});
			this.setState({pickupLandHereMaybe: false});
		}
		console.log('fkd' + plh);
	}
	onPickupBeenHere(e)
	{
		//e.preventDefault();
		var plh = e.target.value;
		if (plh == 'yes')
		{
				this.setState({pickupBeenHere: true});
				this.setState({pickupBeenHereYes: true});
				this.setState({pickupBeenHereNo: false});
				this.setState({pickupBeenHereMaybe: false});
		}
		else if (plh == 'maybe')
		{
			this.setState({pickupBeenHere: true});
			this.setState({pickupBeenHereYes: false});
			this.setState({pickupBeenHereNo: false});
			this.setState({pickupBeenHereMaybe: true});

		}
		else {
			this.setState({pickupBeenHere: false});
			this.setState({pickupBeenHereYes: false});
			this.setState({pickupBeenHereNo: true});
			this.setState({pickupBeenHereMaybe: false});
		}
		console.log('fkd' + plh);
	}
	onDeliveryLandHere(e)
	{
		//e.preventDefault();
		var plh = e.target.value;
		if (plh == 'yes')
		{
				this.setState({deliveryLandHere: true});
				this.setState({deliveryLandHereYes: true});
				this.setState({deliveryLandHereNo: false});
				this.setState({deliveryLandHereMaybe: false});
		}
		else if (plh == 'maybe')
		{
			this.setState({deliveryLandHere: true});
			this.setState({deliveryLandHereYes: false});
			this.setState({deliveryLandHereNo: false});
			this.setState({deliveryLandHereMaybe: true});
		}
		else {
			this.setState({deliveryLandHere: false});
			this.setState({deliveryLandHereYes: false});
			this.setState({deliveryLandHereNo: true});
			this.setState({deliveryLandHereMaybe: false});
		}
		console.log('fkd' + plh);
	}
	onDeliveryBeenHere(e)
	{
		//e.preventDefault();
		var plh = e.target.value;
		if (plh == 'yes')
		{
				this.setState({deliveryBeenHere: true});
				this.setState({deliveryBeenHereYes: true});
				this.setState({deliveryBeenHereNo: false});
				this.setState({deliveryBeenHereMaybe: false});
		}
		else if (plh == 'maybe')
		{
			this.setState({deliveryBeenHere: true});
			this.setState({deliveryBeenHereYes: false});
			this.setState({deliveryBeenHereNo: false});
			this.setState({deliveryBeenHereMaybe: true});

		}
		else {
			this.setState({deliveryBeenHere: false});
			this.setState({deliveryBeenHereYes: false});
			this.setState({deliveryBeenHereNo: true});
			this.setState({deliveryBeenHereMaybe: false});
		}
		console.log('fkd' + plh);
	}

	onBreakItDown(e)
	{
		//e.preventDefault();
		var plh = e.target.value;
		if (plh == 'yes')
		{
				this.setState({brokenDown: true});
				this.setState({brokenDownYes: true});
				this.setState({brokenDownNo: false});
				this.setState({brokenDownMaybe: false});
		}
		else if (plh == 'maybe')
		{
			this.setState({brokenDown: true});
			this.setState({brokenDownYes: false});
			this.setState({brokenDownNo: false});
			this.setState({brokenDownMaybe: true});

		}
		else {
			this.setState({brokenDown: false});
			this.setState({brokenDownYes: false});
			this.setState({brokenDownNo: true});
			this.setState({brokenDownMaybe: false});
		}
	//	console.log('fkd' + plh);
	}
	onFreightReady(e)
	{

		if(e.target.value == 'true')
		{
			this.setState({readyNowTrue: true});
			this.setState({readyNowFalse: false});
this.setState({readyNow: true});
		}
		else {
			this.setState({readyNowTrue: false});
			this.setState({readyNowFalse: true});
			this.setState({readyNow: false});
		}
		console.log('ready:' + this.state.readyNow);
	}
	onContainerSelect(e)
	{
		e.preventDefault();
		this.setState({container: e.target.value});
	}
	onSetWidth(e)
	{
		e.preventDefault();
		this.setState({width: e.target.value});
	}
	onSetLength(e)
	{
		e.preventDefault();
		this.setState({length: e.target.value});
	}
	onSetHeight(e)
	{
		this.setState({height: e.target.value});
	}
	onFromChange(e)
	{
		e.preventDefault();
		this.setState({from: e.target.value});
	}
	onToChange(e)
	{
		//onBlur={this.getFromLatLng.bind(this)} for holding code hre only n/a to this function
		e.preventDefault();
		this.setState({to: e.target.value});
	}
	onDateChange(e)
	{
		this.setState({datestring: e.target.value});
	}
	onTimeChange(e)
	{
		e.preventDefault();
		this.setState({timestring: e.target.value});
	}
	onGroundETE()
	{

	}
/*	onGroundETE(ete)
	{
		if (ete < 60)
		{
			// less than 1 hour
			var ete2 = ete + '';
			var etestring = ete2.substring(0,4) + ' mins';
			this.setState({groundete: etestring});
		}
		else if (ete < 600) {
			// its less than 10 hours
			var hrs = ete/60 + '';
			var hrsstr = hrs.substring(0,1);
			var mins = hrs.substring(1);
			var minsstring = mins*60 + '';
			var minsstring2 = minsstring.substring(0,3);
			var etestring =  hrsstr + ' hours ' + minsstring + ' mins';
			this.setState({groundete: etestring});
		}
		else {
			 // its greater than 10 hours
			 // put message, out of our ground service area
			 this.setState({alert: "This address is outside of our ground service area."});
		}

	}
	calculateAirETE()
		{

			const hangarmarker = new google.maps.Marker({
	    // The below line is equivalent to writing:
	    // position: new google.maps.LatLng(-34.397, 150.644)
	    position: {lat: 42.3472, lng: -83.4550}
	    //map: window.map,
	  });
			var hangarGMapsobj = hangarmarker.getPosition(); // returns a google maps latlng obj for the compute distance function

					var meters = google.maps.geometry.spherical.computeDistanceBetween(this.state.fromLiteral, this.state.toLiteral);
				 var meters2 = google.maps.geometry.spherical.computeDistanceBetween(hangarGMapsobj, this.state.fromLiteral);
				 var meters3 = google.maps.geometry.spherical.computeDistanceBetween(this.state.toLiteral, hangarGMapsobj);

			// need to add this also
	//  mget mileag friom meters
	var mileage = meters/1609.34;
	var mileage2 = meters2/1609.34;
	var returnmileage = meters3/1609.34;
	var retTime = returnmileage/155*60;
	var rt = retTime + 20; // for refueling before home
	this.setState({returnTime: rt});

		 var time0 = mileage2/155*60; // minutes
		 var ft1 = this.niceETEString(time0);
		 var leg1 = 'Time from MHS to Pickup: ' + ft1;
		 this.setState({leg1: leg1});
			var time1 = mileage/155*60; //minutes
			var ft2 = this.niceETEString(time1);
			var leg2 = 'Time from Pickup to Delivery: ' + ft2;
			this.setState({leg2: leg2})
			var time2 = 0;
			var totalmileage = mileage + mileage2;
			if (totalmileage > 260)
			{
				// fuel stop required
				 time2 = 20;
				 this.setState({fuel: 'One 20 min fuel stop'})
			}
			if (totalmileage > 520)
			{
				time2 = 40;
				this.setState({fuel: '2 fuel stops of 20 mins each'});
			}
			if (totalmileage > 780)
			{
				time2 = 60;
				this.setState({fuel: '3 fuel stops 20 mins each'});
			}
			var time3 = 20; //loading
			this.setState({load: '20 mins to load freight'});
			var timeofday =  new Date();
					var time = timeofday.getHours();
					if (time > 8 && time < 18)
					{
						var time4 = 20; // business hours 20 min dispatch
						//console.log('dispatch time business hours: ' + ete + ' mins');
					}
					else
					{
						var time4 = 30; // after hours 1 hour dispatch
						//	console.log('dispatch time after hours: ' + ete + ' mins');
					}
					var disp = 'Time from Authorization to Takeoff: ' + time4 + ' mins';
					this.setState({dispatch: disp})
				var displayete = (time1 + time0 + time2 + time3 + time4);
			var ete = (time1 + time0 + time2 + time3); //minutes

	 this.setState({displayETE: displayete});
			//this.setState({airete: ete});
			this.onAirETE(ete);
		}*/
		niceETEString(ete)
		{
			if (ete < 60)
			{
				// less than 1 hour
				if (ete < 10)
				{
					var ete2 = ete + '';
					var etestring = ete2.substring(0,1) + ' mins';
				}
				else {
					var ete2 = ete + '';
					var etestring = ete2.substring(0,2) + ' mins';
				}
				return etestring;
				//this.setState({airete: etestring});
			}
			else {
				// its less than 10 hours
				var hrs = ete/60 + '';
				var hrsstr = hrs.substring(0,1);
				var mins = hrs.substring(1);
				var minsstring = mins*60 + '';
				var minsstring2 = minsstring.substring(0,2);
				//console.log(minsstring2);
				if(minsstring2.substring(1) == '.')
				{
					minsstring2 = minsstring.substring(0,1);
				}
				var etestring =  hrsstr + ' hours ' + minsstring2 + ' mins';
				return etestring;
				//this.setState({airete: etestring});
			}
		}
	onAirETE(ete0)
	{
		this.setState({submitted: false});
		// TO DO:: se this properly for dipatch bsed on requested time or readyNow
		var timeofday =  new Date();
			if (this.state.readyNow)
			{
					var time = timeofday.getHours();
			} else {
					var time = Number(this.state.timestring.substring(0,2));
			}

				if (this.state.readyNow)
				{
						if (time > 8 && time < 18)
						{
							var time4 = 20; // business hours 20 min dispatch
						//	console.log('dispatch time business hours: ' + ete + ' mins');
						}
						else
						{
							var time4 = 30; // after hours 1 hour dispatch
							//	console.log('dispatch time after hours: ' + ete + ' mins');
						}
					}
					else {
						var time4 = 0; // scheduled flight no dispatch time
					}
			var	ete = ete0 + time4;
		// this functio formats the data for display
		//console.log('total time: ' + ete);
		if (ete < 60)
		{
			// less than 1 hour
			if (ete < 10)
			{
				var ete2 = ete + '';
				var etestring = ete2.substring(0,1) + ' mins';
				if (this.state.readyNow)
				{
					var mns2  = timeofday.getMinutes();
				}
				else {
					var mns2 = Number(this.state.timestring.substring(3,5));
				}

				var newmins = mns2 + ete;
				if(newmins < 10)
				{
				newmins = newmins + '';
					newmins = newmins.substring(0,1); // first digit only if minutes now is 0-9 and ete is 0-9 and addition is still less than 10 (like 2+2)
				newmins = Number(newmins);
				}
				else {
				newmins = newmins + '';
					newmins = newmins.substring(0,2); // first 2 digits 
					newmins = Number(newmins);
				}
				if(newmins > 60)
				{
	// if newmuns is > 60, like if ete is 9 minutes at 57 mins, need to incremnt hour
				time = time + 1;
				newmins = newmins -60;
				}
				if (this.state.readyNow)
				{
						var scds = timeofday.getSeconds();
				}
				else {
						var scds = 0;
				}


				if (scds < 10)
				{
					scds = '0' + scds;
				}
				if (newmins < 10)
				{
					newmins = '0' + newmins;
				}
				this.setState({initialHours: time});
				this.setState({initialSeconds: scds});
				this.setState({initialMins: newmins});

			}else {
			var ete2 = ete + '';
			var etestring = ete2.substring(0,2) + ' mins';
			if (this.state.readyNow)
			{
				var mns2  = timeofday.getMinutes();
			}
			else {
				var mns2 = Number(this.state.timestring.substring(3,5));
			}
			var newmins = mns2 + ete;
			if(newmins < 10)
			{
			newmins = newmins + '';
				newmins = newmins.substring(0,1); // first digit only if minutes now is 0-9 and ete is 0-9 and addition is still less than 10 (like 2+2)
			newmins = Number(newmins);
			}
		else if (newmins < 100) {
			newmins = newmins + '';
				newmins = newmins.substring(0,2); // first 2digits if ete + now is less than 100 like 45 minuts at 04 minutes
				newmins = Number(newmins);
			}
			else {
			newmins = newmins + '';
			newmins = newmins.substring(0,2); // first 3 digits if ete + now is > 100 like 59min ete at 59 mins
			newmins = Number(newmins);
			}
			if (this.state.readyNow)
			{
					var scds = timeofday.getSeconds();
			}
			else {
					var scds = 0;
			}

			if (newmins > 60)
			{
				newmins = newmins-60;
				var newhrs2 = time + 1;
				if (newhrs2 > 24)
				{
					newhrs2 = newhrs2 -24;
				}
			}
			if (scds < 10)
			{
				scds = '0' + scds;
			}
			if (newmins < 10)
			{
				newmins = '0' + newmins;
			}
			this.setState({initialHours: newhrs2});
			this.setState({initialSeconds: scds});
			this.setState({initialMins: newmins});
		}
			this.setState({airete: etestring});
		}
		else if (ete < 600) {
			// its less than 10 hours
			var hrs = ete/60 + '';
			var hrsstr = hrs.substring(0,1);
			var mins = hrs.substring(1);
			var minsstring = mins*60 + '';
			var minsstring2 = minsstring.substring(0,2);
			if (this.state.readyNow)
			{
				var mns2  = timeofday.getMinutes();
			}
			else {
				var mns2 = Number(this.state.timestring.substring(3,5));
			}
			var newmins = mns2 + Number(minsstring2);
			if (this.state.readyNow)
			{
					var scds = timeofday.getSeconds();
			}
			else {
					var scds = 0;
			}


			if (newmins > 60)
			{
				newmins = newmins-60;
				var newhrs2 = time + 1;
				if (newhrs2 > 24)
				{
					newhrs2 = newhrs2 -24;
				}
			}
			else {
				newhrs2 = time;
			}
			var thrs2 = newhrs2 + Number(hrsstr);
			if (thrs2 > 24)
			{
				thrs2 = thrs2 - 24;
			}
			if (scds < 10)
			{
				scds = '0' + scds;
			}
			if (newmins < 10)
			{
				newmins = '0' + newmins;
			}
			this.setState({initialHours: thrs2});
			this.setState({initialSeconds: scds});
			this.setState({initialMins: newmins});
		//	console.log(minsstring2);
			if(minsstring2.substring(1) == '.')
			{
				minsstring2 = minsstring.substring(0,1);
			}
			var etestring =  hrsstr + ' hours ' + minsstring2 + ' mins';
			this.setState({airete: etestring});
		}
		else {
			 // its greater than 10 hours
			 this.setState({airete: "We're sorry, this is too far for immediate availaiblity. Please call us for more information."});
			 this.setState({initialHours: '00'});
			 this.setState({initialSeconds: '00'});
			 this.setState({initialMins: '00'});
			 this.setState({leg2: 'N/A'});
			this.setState({leg1: 'N/A'});
			this.setState({load: 'N/A'});
			this.setState({fuel: 'N/A'});
			this.setState({dispatch: 'N/A'});
			// set modal content to sorry
		}
		// show modal and display
	//	this.setState({timingAvailable: true});
		// create some html below the butti in in case tge modal fails

this.timingModal.show();
this.setState({loadingTiming: false});
//this.startTimer();
if(this.state.readyNow)
{
this.stopTimer = setInterval(function() {
	var newsecundos = Number(this.state.initialSeconds) + 1;
	if (newsecundos < 60)
	{

		if (newsecundos < 10)
		{
			var ns2 = '0' + newsecundos;
			this.setState({initialSeconds: ns2});

		}
		else {
				this.setState({initialSeconds: newsecundos});
		}
	} else {
		this.setState({initialSeconds: '00'});
		var newminutos = Number(this.state.initialMins) + 1;
		if (newminutos < 60)
		{
				if (newminutos < 10)
				{
					var nm2 = '0' + newminutos;
					this.setState({initialMins: nm2});

				}
				else {
					this.setState({initialMins: newminutos});
				}
		}	else {
			this.setState({initialMins: '00'});
			var newhoras = Number(this.state.initialHours) + 1;
			if (newhoras < 24)
			{
				if (newhoras < 10)
				{
					var nm2 = '0' + newhoras;
					this.setState({initialHours: nm2});

				}
				else {
						this.setState({initialHours: newhoras});
				}
			}
			else {
				this.setState({initialHours: '00'});
			}
		}

	}
}.bind(this), 1000);
}
var myModalEl = document.getElementById('timingModal');
if (this.state.readyNow)
{
myModalEl.addEventListener('hidden.bs.modal', function() {
	clearInterval(this.stopTimer);
}.bind(this));
}
	}

	onGetTimingClick(e) {
		e.preventDefault();
		this.setState({submitError: ''});
		if (this.state.needUsername && this.state.name.length < 1)
		{
			this.setState({submitError: 'Please enter your name for this request.'});
			return false;
		}

	  if (this.state.needUsername && this.state.name.length > 1)
		{
			var user = firebase.auth().currentUser;
			var name = this.state.name;
			user.updateProfile({
			  displayName: name,


			}).then(function() {

			}.bind(this)).catch(function(error) {

			}.bind(this));
		}
		if (this.state.needCompany && this.state.requestorCompany.length > 1)
		{
			this.dbRef.ref('users/' + this.uid + '/company').set(this.state.requestorCompany);
		}
		else if (this.state.needCompany && this.state.requestorCompany.length == 0)
		{
			this.setState({submitError: 'Please enter your company name for this request.'});
			return false;
		}
		// validate that we have all required data

		if (this.state.from && this.state.to && this.state.toLiteral && this.state.fromLiteral)
		{
			if (this.state.fromStreetNumber.length > 0 && this.state.toStreetNumber.length > 0) {
				this.setState({loadingTiming: true});
				const hangarmarker = new google.maps.Marker({
		    // The below line is equivalent to writing:
		    // position: new google.maps.LatLng(-34.397, 150.644)
		    position: {lat: 42.3472, lng: -83.4550}
		    //map: window.map,
		  });
				var hangarGMapsobj = hangarmarker.getPosition(); // returns a google maps latlng obj for the compute distance function

						var meters = google.maps.geometry.spherical.computeDistanceBetween(this.state.fromLiteral, this.state.toLiteral);
					 var meters2 = google.maps.geometry.spherical.computeDistanceBetween(hangarGMapsobj, this.state.fromLiteral);
					 var meters3 = google.maps.geometry.spherical.computeDistanceBetween(this.state.toLiteral, hangarGMapsobj);

				// need to add this also
		//  mget mileag friom meters
		var mileage = meters/1609.34;
		var mileage2 = meters2/1609.34;
		var returnmileage = meters3/1609.34;
		var retTime = returnmileage/155*60;
		var rt1 = retTime + 20; // for refueling before home
		var rt = rt1 + '';
		this.setState({returnTime: rt});

			 var time0 = mileage2/155*60; // minutes
			 var ft1 = this.niceETEString(time0);
			  ft1 = ft1 + ' - Time from McMahon to Pickup Location';
			  var leg1 = time0 + '';
			  leg1 = leg1.substring(0,6);
			 this.setState({leg1: ft1});
				var time1 = mileage/155*60; //minutes
				var ft2 = this.niceETEString(time1);
				 ft2 = ft2 + ' - Time from Pickup to Delivery';
				var leg2 = time1 + '';
				leg2 = leg2.substring(0,6);
				this.setState({leg2: ft2});
				var time2 = 0;
				var totalmileage = mileage + mileage2;

				if (totalmileage > 300 && totalmileage < 600)
				{
					// fuel stop required
					 time2 = 20;
					 var fuel = '20';
					 this.setState({fuel: '20 mins - 1 Fuel Stop'})
				}
				else if (totalmileage > 599 && totalmileage < 900)
				{
					time2 = 40;
					var fuel = '40';
					this.setState({fuel: '40 mins - 2 fuel stops'});
				}
				else if (totalmileage > 899)
				{
					time2 = 60;
					var fuel = '60';
					this.setState({fuel: '60 mins - 3 fuel stops'});
				}
				else {
					var fuel = '';
				}
				var time3 = 20; //loading
				var load = '20';
				this.setState({load: '20 mins - Load Time'});
				var timeofday =  new Date();
						var time = timeofday.getHours();
						if (this.state.readyNow)
						{
								if (time > 8 && time < 18)
								{
									var time4 = 20; // business hours 20 min dispatch
									//console.log('dispatch time business hours: ' + ete + ' mins');
								}
								else
								{
									var time4 = 30; // after hours 1 hour dispatch
									//	console.log('dispatch time after hours: ' + ete + ' mins');
								}
								var disp1 =  time4 + ' mins '+ '- Time from Authorization to Airborne';
								var disp = time4 + '';
						}
						else {
							var time4 = 0; // no dispatch time for scheduled flight
							var disp1 = '';
							var disp = '0';
						}



						this.setState({dispatch: disp1});
						var time5 = 0;
						if(this.state.pickupCountry == 'Canada' || this.state.deliveryCountry == 'Canada')
						{
							 time5 = 60;
							 var customs = '60'
							 this.setState({customs: '1 hour - Estimated Customs Clearance Time'});
						}
						else {
							var customs = '0';
							this.setState({customs: ''});
						}

					var displayete = (time1 + time0 + time2 + time3 + time4 + time5);
				var ete = (time1 + time0 + time2 + time3 + time5); //minutes
						var dee = displayete + '';
		 this.setState({displayETE: displayete});
				//this.setState({airete: ete});
				this.onAirETE(ete);
		// cehck the land heres and set the fromLZ and toLZ literals properly
//		var hangar = {lng: -83.4550, lat: 42.3472};
	/*	if (this.state.landFrom)
		{
			this.setState({fromLZLiteral: this.state.fromLiteral});
		}
		else {
			this.setState({fromLZLiteral: hangar});
		}
		if (this.state.landTo)
		{
			this.setState({toLZLiteral: this.state.toLiteral});
		}
		else {
			this.setState({toLZLiteral: hangar});
		}*/

//this.setState({submitted: true}); THIS LINE IS COMMENTED TO PREEMT TIMINGMAP FROM CALCUKATUNG AND CALLING THE CllbCK, CHnged so nick cam see the algorithm

	var fromLiteralLat = this.state.fromLiteral.lat();
	var fromLiteralLng = this.state.fromLiteral.lng();
	var toLiteralLat = this.state.toLiteral.lat();
	var toLiteralLng = this.state.toLiteral.lng();
var dn = this.username ? this.username : this.state.name ? this.state.name : 'unknown';

var refn = Math.random() * (99999 - 10000) + 10000;
var refn2 = refn + '';
var refnumber = refn2.substring(0, 5);
this.setState({refnumber: refnumber});
if (this.state.readyNow)
{
	var datnhj =  new Date();
	var djs = datnhj.getMonth();
	var monthnow = Number(djs) + 1;

	var today = monthnow + '/' + datnhj.getDate() + '/' + datnhj.getFullYear();
	var rightnow = datnhj.getHours() + ':' + datnhj.getMinutes();
	this.setState({datestring: today});
	this.setState({timestring: rightnow});
}
else {
	var today = this.state.datestring;
	var rightnow = this.state.timestring;
}
var nowstamp = Date.now() + '';
		this.fireRef.collection("requested").add({
    pickupCompany: this.state.pickupCompany,
		pickupState: this.state.pickupState,
		pickupCity: this.state.pickupCity,
		deliveryCompany: this.state.deliveryCompany,
		deliveryCity: this.state.deliveryCity,
		deliveryState: this.state.deliveryState,
		fromAddressString: this.state.from,
		toAddressString: this.state.to,
		toLiteralLat: toLiteralLat,
		fromLiteralLat: fromLiteralLat,
		toLiteralLng: toLiteralLng,
		fromLiteralLng: fromLiteralLng,
		container: this.state.container,
		qty: this.state.qty,
		length: this.state.length,
		width: this.state.width,
		height: this.state.height,
		weight: this.state.weight,
		readyNow: this.state.readyNow,
		datestring: today,
		timestring: rightnow,
		brokenDown: this.state.brokenDown,
		uid: this.uid,
		pickupLandHere: this.state.pickupLandHere,
		deliveryLandHere: this.state.deliveryLandHere,
		pickupBeenHere: this.state.pickupBeenHere,
		deliveryBeenHere: this.state.deliveryBeenHere,
		author: dn,
		dispatchTime: disp,
		leg1Time: leg1,
		leg2Time: leg2,
		fuelTime: fuel,
		loadTime: load,
		customs: customs,
		displayETE: dee,
		toStreetName: this.state.toStreetName,
		toStreetNumber: this.state.toStreetNumber,
		fromStreetName: this.state.fromStreetName,
		fromStreetNumber: this.state.fromStreetNumber,
		toPostal: this.state.toPostal,
		fromPostal: this.state.fromPostal,
		returnTime: rt,
		reference: refnumber,
		requestorCompany: this.state.requestorCompany,
		created: nowstamp
})
.then(function(docRef) {

 this.fireRef.collection("requested").doc(docRef.id).update({tripDocID: docRef.id});


}.bind(this)).catch(error => {
	console.log(error.message);
});
} else {
	// no streetNumber
	this.setState({submitError: 'A street number for the pickup and delivery addresses is required.'});
}

} else {
	// not enough info
		this.setState({submitError: 'Pickup and delivery addresses are necessary.'});
}
	}
	onNameChange(e) {

		this.setState({name: e.target.value});
	}
	onCompany(e) {
		this.setState({requestorCompany: e.target.value});
	}
	render() {
    return (




  <div className="container-fluid pb-3" style={{marginTop: '10px'}}>
	<div className="row">

			<div className="col-md-6 col-lg-5">
			<div className="bg-light border rounded-3" id="form-outline" style={{padding: "20px"}}>

			<form className="needs-validation" noValidate>
				<div className="row g-3">
					<div className="col-12">
						<h5>Pickup Location</h5>
						<label htmlFor="firstName" className="form-label">Company Name</label>
						<input type="text" className="form-control" id="firstName" placeholder="Company Name (optional)" value={this.state.pickupCompany} required onChange={this.onPickupCompanyChange.bind(this)} maxLength='30' />
						<div className="invalid-feedback">
							Company name is required.
						</div>
					</div>

					<div className="col-12">
						<label htmlFor="address" className="form-label">Pickup Address</label>
						<input id="from" type="text" className="form-control" placeholder="1234 Main St" required onChange={this.onFromChange.bind(this)} value={this.state.from} maxLength="250" />
						<div className="invalid-feedback">
							Please enter the pickup address.
						</div>

					</div>
							<div className="col-6">
							<h6>Can We Land a Helicopter Here?</h6>
					<div className="my-3">
						<div className="form-check">
							<input id="credit" name="PickupLandHere" type="radio" className="form-check-input" style={{boxShadow: 'none'}} checked={this.state.pickupLandHereYes} onChange={this.onPickupLandHere.bind(this)} value="yes" />
							<label className="form-check-label" htmlFor="credit">Yes</label>
						</div>
						<div className="form-check">
							<input id="debit" name="PickupLandHere" type="radio" className="form-check-input" style={{boxShadow: 'none'}} checked={this.state.pickupLandHereNo}  onChange={this.onPickupLandHere.bind(this)} value="no" />
							<label className="form-check-label" htmlFor="debit">No</label>
						</div>
						<div className="form-check">
							<input id="debit" name="PickupLandHere" type="radio" className="form-check-input" style={{boxShadow: 'none'}} checked={this.state.pickupLandHereMaybe}  onChange={this.onPickupLandHere.bind(this)} value="maybe" />
							<label className="form-check-label" htmlFor="debit">Not Sure</label>
						</div>

					</div>

					</div>

					<div className="col-6">
					<h6>Have We Been Here Before?</h6>
			<div className="my-3">
				<div className="form-check">
					<input id="credit" name="PickupBeenBefore" type="radio" className="form-check-input" checked={this.state.pickupBeenHereYes} onChange={this.onPickupBeenHere.bind(this)} value="yes" />
					<label className="form-check-label" htmlFor="credit">Yes</label>
				</div>
				<div className="form-check">
					<input id="debit" name="PickupBeenBefore" type="radio" className="form-check-input" checked={this.state.pickupBeenHereNo} onChange={this.onPickupBeenHere.bind(this)} value="no" />
					<label className="form-check-label" htmlFor="debit">No</label>
				</div>
				<div className="form-check">
					<input id="debit" name="PickupBeenBefore" type="radio" className="form-check-input" checked={this.state.pickupBeenHereMaybe} onChange={this.onPickupBeenHere.bind(this)} value="maybe" />
					<label className="form-check-label" htmlFor="debit">Not Sure</label>
				</div>

			</div>

			</div>

				<div className="col-12">
					<hr />
				</div>

				<div className="col-12">
					<h5>Delivery Location</h5>
					<label htmlFor="firstName" className="form-label">Company Name</label>
					<input type="text" className="form-control" id="firstName" placeholder="Company Name (optional)" value={this.state.deliveryCompany} required onChange={this.onDeliveryCompanyChange.bind(this)} maxLength="30" />
					<div className="invalid-feedback">
						Company name is required.
					</div>
				</div>

				<div className="col-12">
					<label htmlFor="address" className="form-label">Delivery Address</label>
					<input id="to" type="text" className="form-control" placeholder="1234 Main St" required onChange={this.onToChange.bind(this)} value={this.state.to} maxLength="250" />
					<div className="invalid-feedback">
						Please enter the delivery address.
					</div>

				</div>
						<div className="col-6">
						<h6>Can We Land a Helicopter Here?</h6>
				<div className="my-3">
					<div className="form-check">
						<input id="credit" name="DeliveryLandHere" type="radio" className="form-check-input" checked={this.state.deliveryLandHereYes} onChange={this.onDeliveryLandHere.bind(this)} value="yes" />
						<label className="form-check-label" htmlFor="credit">Yes</label>
					</div>
					<div className="form-check">
						<input id="debit" name="DeliveryLandHere" type="radio" className="form-check-input" checked={this.state.deliveryLandHereNo} onChange={this.onDeliveryLandHere.bind(this)} value="no" />
						<label className="form-check-label" htmlFor="debit">No</label>
					</div>
					<div className="form-check">
						<input id="debit" name="DeliveryLandHere" type="radio" className="form-check-input" checked={this.state.deliveryLandHereMaybe} onChange={this.onDeliveryLandHere.bind(this)} value="maybe" />
						<label className="form-check-label" htmlFor="debit">Not Sure</label>
					</div>

				</div>

				</div>

				<div className="col-6">
				<h6>Have We Been Here Before?</h6>
		<div className="my-3">
			<div className="form-check">
				<input id="dbny" name="DeliveryBeenBefore" type="radio" className="form-check-input" checked={this.state.deliveryBeenHereYes} onChange={this.onDeliveryBeenHere.bind(this)} value="yes" />
				<label className="form-check-label" htmlFor="credit">Yes</label>
			</div>
			<div className="form-check">
				<input id="dbnn" name="DeliveryBeenBefore" type="radio" className="form-check-input" checked={this.state.deliveryBeenHereNo} onChange={this.onDeliveryBeenHere.bind(this)} value="no" />
				<label className="form-check-label" htmlFor="debit">No</label>
			</div>
			<div className="form-check">
				<input id="dbnm" name="DeliveryBeenBefore" type="radio" className="form-check-input" checked={this.state.deliveryBeenHereMaybe} onChange={this.onDeliveryBeenHere.bind(this)} value="maybe" />
				<label className="form-check-label" htmlFor="debit">Not Sure</label>
			</div>

		</div>

		</div>


		<div className="col-12">
			<hr />
		</div>
					<div className="col-12">
						<h5>Freight Weight and Dimensions</h5>
					</div>


					<div className="col-6">
						<h6>Container Type</h6>
						<select className="form-select" aria-label="Default select example" onChange={this.onContainerSelect.bind(this)} value={this.state.container}>
						  <option value="skids">Skids</option>
						  <option value="totes">Totes</option>
						  <option value="boxes">Boxes</option>
						  <option value="pieces">Pieces (No container)</option>
						</select>
					</div>

					<div className="col-6">
						<h6>Quantity</h6>

						<div className="input-group has-validation">
						<input type="text" className="form-control" id="quantity" placeholder="1" required onChange={this.onQtyChange.bind(this)} value={this.state.qty} maxLength="5" />

						<div className="invalid-feedback">
							Quantity required.
						</div>
						</div>
					</div>
					
					<div className="col-4 col-sm-3 col-md-4 col-xxl-3">

						<label htmlFor="length" className="form-label">Length (in)</label>
						<div className="input-group has-validation">

							<input type="text" className="form-control" id="length" placeholder="48" value={this.state.length} onChange={this.onSetLength.bind(this)} maxLength="3" />
							<span className="input-group-text">in</span>
						<div className="invalid-feedback">
								Dims are required.
							</div>
						</div>
					</div>

					<div className="col-4 col-sm-3 col-md-4 col-xxl-3">
						<label htmlFor="width" className="form-label">Width (in)</label>
						<div className="input-group has-validation">

							<input type="text" className="form-control" id="width" placeholder="48" value={this.state.width} onChange={this.onSetWidth.bind(this)} maxLength="3" />
							<span className="input-group-text">in</span>
						<div className="invalid-feedback">
								Dims are required
							</div>
						</div>
					</div>

					<div className="col-4 col-sm-3 col-md-4 col-xxl-3">
						<label htmlFor="height" className="form-label">Height (in)</label>
						<div className="input-group has-validation">

							<input type="text" className="form-control" id="height" placeholder="48" onChange={this.onSetHeight.bind(this)} value={this.state.height} maxLength="3" />
							<span className="input-group-text">in</span>
						<div className="invalid-feedback">
								Dims are required.
							</div>
						</div>
					</div>

					<div className="col-5 col-sm-4 col-md-5 col-xxl-4">
						<label htmlFor="weight" className="form-label">Weight (lb)</label>
						<div className="input-group has-validation">
						<input type="text" className="form-control" id="weight" placeholder="0" value={this.state.weight} onChange={this.onWeightChange.bind(this)} maxLength="5" />
						<span className="input-group-text">lb</span>
						<div className="invalid-feedback">
							Weight required.
						</div>
						</div>
					</div>



					<div className="col-12">
					<h6>Can The Freight Be Broken Down or Loose Loaded?</h6>
			<div className="my-3">
				<div className="form-check">
					<input id="bdy" name="BrokenDown" type="radio" className="form-check-input" checked={this.state.brokenDownYes} onChange={this.onBreakItDown.bind(this)} value="yes" />
					<label className="form-check-label" htmlFor="credit">Yes</label>
				</div>
				<div className="form-check">
					<input id="bdn" name="BrokenDown" type="radio" className="form-check-input" checked={this.state.brokenDownNo} onChange={this.onBreakItDown.bind(this)} value="no" />
					<label className="form-check-label" htmlFor="debit">No</label>
				</div>
				<div className="form-check">
					<input id="bdm" name="BrokenDown" type="radio" className="form-check-input" checked={this.state.brokenDownMaybe} onChange={this.onBreakItDown.bind(this)} value="maybe" />
					<label className="form-check-label" htmlFor="debit">Not Sure</label>
				</div>

			</div>

			</div>

			<div className="col-12">
				<hr />
			</div>


			<div className="col-6">
			<h6>Is The Freight Ready To Ship Now?</h6>

					<div className="my-3">
						<div className="form-check">
							<input id="ready" name="ReadyNow" type="radio" className="form-check-input" checked={this.state.readyNowTrue} onChange={this.onFreightReady.bind(this)} value='true' />
							<label className="form-check-label" htmlFor="ready">Yes</label>
						</div>
						<div className="form-check">
							<input id="notready" name="ReadyNow" type="radio" className="form-check-input" checked={this.state.readyNowFalse} onChange={this.onFreightReady.bind(this)} value='false' />
							<label className="form-check-label" htmlFor="notready">No</label>
						</div>


					</div>
			</div>
			{this.state.readyNow ? '' :
			<div className="col-6">
			<h6>When Will It Be Ready to Ship?</h6>
					<div className="my-3">
						<label htmlFor="date" className="form-label">Date Ready</label>
						<div className="input-group has-validation">
								<input id="credit" name="date" type="date" className="form-control" value={this.state.datestring} onChange={this.onDateChange.bind(this)} />
								<div className="invalid-feedback">
										Provide a date if possible.
									</div>
							</div>
							<label htmlFor="time" className="form-label">Approx Time Ready (US-Eastern)</label>
							<div className="input-group has-validation">
								<input id="time" name="time" type="time" className="form-control" value={this.state.timestring} onChange={this.onTimeChange.bind(this)} />
								<div className="invalid-feedback">
										Provide an approx time if possible.
									</div>
							</div>
					</div>
			</div>}




</div>


{this.state.needUsername ? <div><div className="col-12">
	<hr />
</div>

<div className="col-12">
	<label htmlFor="name" className="form-label">Name</label>
	<input id="name" type="text" className="form-control" placeholder="Your name (e.g. John Smith)" required onChange={this.onNameChange.bind(this)} value={this.state.name} maxLength="30" />
	<div className="invalid-feedback">
	</div>
	<small>Please enter a name for this timing request. This will help MHS staff locate your request if you decide to authorize this trip.</small>

</div></div> : ''}

{this.state.needCompany ? <div><div className="col-12">
	<hr />
</div>

<div className="col-12">
	<label htmlFor="company" className="form-label">Company</label>
	<input id="company" type="text" className="form-control" placeholder="Company name" onChange={this.onCompany.bind(this)} value={this.state.requestorCompany} maxLength="30"/>
	<div className="invalid-feedback">
	</div>
	<small>Please enter the name of the company you work for. This is essential for MHS staff to locate your request if you decide to authorize this trip as multiple brokers may have requested this triming information.</small>

</div></div> : ''}


				<hr className="my-4" />
				{this.state.submitError ? <div className="alert alert-danger">{this.state.submitError}</div> : ''}
				{this.state.loadingTiming ? <div class="d-flex justify-content-center">
				  <div class="spinner-border" role="status">
				    <span class="visually-hidden">Loading...</span>
				  </div>
				</div> : ''}
				<button className="w-100 btn btn-primary btn-lg" type="submit" onClick={this.onGetTimingClick.bind(this)} disabled={this.state.submitted}>Get Timing Estimate</button>
			</form>
</div>
      </div>
			  <div id="size-target" className="col-md-5 col-lg-7">
      <div className="bg-light border rounded-3">
			<TimingMap from={this.state.fromLiteral} to={this.state.toLiteral} onGroundETE={this.onGroundETE.bind(this)} onAirETE={this.onAirETE.bind(this)} type={this.state.type} landTo={true} landFrom={true} toLZ={this.state.toLZLiteral} fromLZ={this.state.fromLZLiteral} submitted={this.state.submitted} />

      </div>
</div>
    </div>


		<div className="modal fade" id="timingModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Estimated Timing</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
			<p><strong>The estimated timing for the trip is as follows:</strong></p>
			<div>{this.state.dispatch}</div>
			<div>{this.state.leg1}</div>
			<div>{this.state.load}</div>
			<div>{this.state.fuel}</div>
			<div>{this.state.leg2}</div>
			<div>{this.state.customs}</div>
			<hr />
				<p style={{fontSize: '1.4rem', marginBottom: ".5rem"}}>Estimated Time to Delivery*: <strong>{this.state.airete}</strong></p>
				<p style={{fontSize: '1.4rem', marginBottom: ".5rem"}}>Delivery ETA*: <strong style={{color: 'red'}}>{this.state.initialHours}:{this.state.initialMins}:{this.state.initialSeconds}</strong> (Eastern Time)</p>
				<p className="lead"><u>Call immediately to authorize this trip and launch aircraft:</u></p>
				<h5>1-800-752-8590 ext. 1 (toll-free)</h5>
				<h5>1-734-459-5980 ext. 1 (international)</h5>
				<p>Mention request reference number: <strong>{this.state.refnumber}</strong></p>
				<hr />
				<small>*Timing estimates are based on direct landing at both the pickup and the delivery locations provided. Unsuitable landing sites may require use of an airport and additional ground transportation time. This timing does not automatically account for current weather conditions. All timing is subject to change due to weather, aircraft and crew availability, fuel requirements, after hours call-out time, supplier and ground transit delays, customs clearance delays, and other factors. All flight times must be verified with McMahon flight staff for accuracy prior to booking.</small>


      </div>

    </div>
  </div>
</div>
  </div>

      )
		}

}
export default Timing
