import React from 'react';
import firebase from 'firebase/app';
import {Dropdown} from 'bootstrap';
import {Collapse} from 'bootstrap';
import {ReactComponent as Alarm} from './icons/stopwatch.svg';
import {ReactComponent as Pin} from './icons/geo-alt-fill.svg';
import {ReactComponent as List} from './icons/list-ul.svg';
import {ReactComponent as Bars} from './icons/reception-4.svg';

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

    };
    this.dbRef = firebase.database();
	}
  componentDidMount() {

    //go();
		var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
  return new Dropdown(dropdownToggleEl);
});
/*var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
var collapseList = collapseElementList.map(function (collapseEl) {
  return new Collapse(collapseEl);
});*/
  }
	componentWillUnmount() {
		var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
  return new Dropdown(dropdownToggleEl).dispose();
});
/*var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
var collapseList = collapseElementList.map(function (collapseEl) {
  return new Collapse(collapseEl).dispose();
});*/

	}
	viewChange(news, e) {
    e.preventDefault();

    this.props.onLinkClick(news);
  }


	render() {
    return (
			<div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{width: '280px', flexGrow: "1"}}>
    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
      <svg className="bi me-2" width="40" height="32"></svg>
      <span className="fs-4">Freight Options</span>
    </a>
    <hr />
    <ul className="nav nav-pills flex-column mb-auto">
      <li className="nav-item">
        <a href="#" className={this.props.activeLink == 'availability' ? "nav-link active" : "nav-link text-white"} onClick={this.viewChange.bind(this, 'availability')}>
          <Bars style={{marginRight: "7px"}} />
          Availability
        </a>
      </li>
      <li>
        <a href="#" className={this.props.activeLink == 'timing' ? "nav-link active" : "nav-link text-white"}  onClick={this.viewChange.bind(this, 'timing')}>
          <Alarm style={{marginRight: "7px"}} />
          Get Timing
        </a>
      </li>
      <li>
        <a href="#" className={this.props.activeLink == 'home' ? "nav-link active" : "nav-link text-white"} onClick={this.viewChange.bind(this, 'home')}>
          <Pin style={{marginRight: "7px"}} />
          Track
        </a>
      </li>
			<li>
        <a href="#" className="nav-link text-white">
          <List style={{marginRight: "7px"}} />
          My Trips
        </a>
      </li>



    </ul>
    <hr />
    <div className="dropdown">
      <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
        <strong>mdo</strong>
      </a>
      <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
        <li><a className="dropdown-item" href="#">Privacy</a></li>
        <li><a className="dropdown-item" href="#">Terms</a></li>
				<li><a className="dropdown-item" href="#">Help</a></li>
        <li><a className="dropdown-item" href="#" onClick={this.viewChange.bind(this, 'account')}>Account</a></li>
        <li><hr className="dropdown-divider" /></li>
        <li><a className="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div>
  </div>
      )
		}

}
export default Sidebar
