import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import $ from "jquery";
const google = window.google;
// requires global variables map, initMap(), window and jquery from index.html


class TrackMap extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
        positions: [],
        latestposition: [],
        activeVehicle: 'van',
        positionsWithKeys: [],
				to: '',
				from: '',
				fromLZ: '',
				toLZ: '',
				landTo: '',
				landFrom: ''
    };
    this.dbRef = firebase.database();
		this.fireRef = firebase.firestore();

    // get tripno from props
		if (window.shipicon)
    {
      window.shipicon.setMap(null);
    }
		if (window.shipicon1)
    {
      window.shipicon1.setMap(null);
    }
		if (window.shipicon2)
    {
      window.shipicon2.setMap(null);
    }
		if (window.shipicon3)
    {
      window.shipicon3.setMap(null);
    }
    if (window.line)
    {
      window.line.setMap(null);
    }
		if (window.to)
		{
			window.to.setMap(null);
		}
		if (window.hangar)
		{
			window.hangar.setMap(null);
		}
		if (window.from)
		{
			window.from.setMap(null);
		}
		if (window.forwardline)
		{
			window.forwardline.setMap(null);
		}

		 window.shipicon = new google.maps.Marker({



    position: {lng: -83.4550, lat: 42.3472},
    map: window.map,
		labelOrigin: google.maps.Point(1000,0),
    icon: {
				path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 6,
				color: 'red',
        fillColor: "#08B2FC",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0 //this is how to rotate the pointer

    }
});
/*
window.shipiconNew = new google.maps.Marker({



position: {lng: -83.4550, lat: 42.3472},
map: window.map,
icon: google.maps.Icon({
	url: "https://go.mcmahonnow.com/images/helicopter.png",
	labelOrigin: google.maps.Point(10,10)

}),
label: google.maps.MarkerLabel({
	text: this.props.eta
})
});*/

      window.linedata = [{lng: -83.4550, lat: 42.3472}];
       window.line = new google.maps.Polyline({
                  path: window.linedata,
                  geodesic: true,
                  strokeColor: '#08B2FC',
                  strokeOpacity: 1.0,
                  strokeWeight: 4,

                  });
                 window.line.setMap(window.map);

								 window.forwardlinedata = [];

								 var lineSymbol = {
								 path: 'M 0,-1 0,1',
								 strokeOpacity: 1,
								 strokeColor: '#08B2FC',
								 strokeWeight: 4,
								 scale: 4
							 };
							 window.forwardline = new google.maps.Polyline({
							 path: window.forwardlinedata,
							 strokeOpacity: 0,
							 icons: [{
								 icon: lineSymbol,
								 offset: '0',
								 repeat: '20px'
							 }],
							 map: window.map
						 });
							 /*  window.line = new google.maps.Polyline({
														 path: window.linedata,
														 geodesic: true,
														 strokeColor: '#08B2FC',
														 strokeOpacity: 1.0,
														 strokeWeight: 4,

													 });*/
									window.forwardline.setMap(window.map);
	}

  componentDidMount() {

     this.run();
/*	this.dbRef.ref('active/' + this.props.tripno + '/initial/docid').once('value').then(snapshot => {
		if(snapshot.exists())
		{
		var docid = snapshot.val();

		 this.fireRef.collection('requested').doc(docid).get().then((doc) => {


           var trip = doc.data();


				//	this.setState({from: trip.from}); already have this
				//	this.setState({to: trip.to}); already have this
					this.setState({to: trip.toLiteral});
					this.setState({from: trip.fromLiteral});
				//	this.setState({landTo: trip.landTo}); dont need since dispatch sets the proper lz in the literals above
				//	this.setState({landFrom: trip.landFrom}); dont need


 		}).catch((error) => {

 		});
	}
	else {
		// snapshot doesnt exist (old style trip beofre the new app or failed to enter addresses)

	}
});*/
this.dbRef.ref('active/' + this.props.tripno + '/initial/toLiteral').once('value').then(snapshot => {
	if(snapshot.exists())
	{

		var toLiteral = snapshot.val();

			//	this.setState({from: trip.from}); already have this
			//	this.setState({to: trip.to}); already have this
				this.setState({to: toLiteral});
				window.to = new google.maps.Marker({



				position: toLiteral,
				map: window.map,
				icon: {
						 path: google.maps.SymbolPath.CIRCLE,
					 scale: 6,
					 fillColor: "#08B2FC",
					 fillOpacity: 0.8,
					 strokeWeight: 2,
					 rotation: 0 //this is how to rotate the pointer
				}
				});

			//	this.setState({landTo: trip.landTo}); dont need since dispatch sets the proper lz in the literals above
			//	this.setState({landFrom: trip.landFrom}); dont need


}
else {
	// snapshot doesnt exist (old style trip beofre the new app or failed to enter addresses)

}
});
this.dbRef.ref('active/' + this.props.tripno + '/initial/fromLiteral').once('value').then(snapshot => {
	if(snapshot.exists())
	{

		var fromLiteral = snapshot.val();

			//	this.setState({from: trip.from}); already have this
			//	this.setState({to: trip.to}); already have this
				this.setState({from: fromLiteral});
				window.from = new google.maps.Marker({



			  position: fromLiteral,
			  map: window.map,
			  icon: {
			 			path: google.maps.SymbolPath.CIRCLE,
			 		scale: 6,
			 		fillColor: "#08B2FC",
			 		fillOpacity: 0.8,
			 		strokeWeight: 2,
			 		rotation: 0 //this is how to rotate the pointer
			  }
			  });
			//	this.setState({landTo: trip.landTo}); dont need since dispatch sets the proper lz in the literals above
			//	this.setState({landFrom: trip.landFrom}); dont need


}
else {
	// snapshot doesnt exist (old style trip beofre the new app or failed to enter addresses)

}
});


this.dbRef.ref('active/' + this.props.tripno + '/initial/imei').once('value').then(vehicle => {
  if (vehicle.val() == '300234062056210')
  {
    this.setState({activeVehicle: 'van'});
    //console.log("active:" + this.state.activeVehicle);
  }
  else
  {
    this.setState({activeVehicle: 'helicopter'});
  //  console.log("active:" + this.state.activeVehicle);
  }

  if (this.state.activeVehicle == 'van')
   {

   }
   else
   {

   }
});

this.dbRef.ref('active/' + this.props.tripno + '/initial/imei').on('child_changed', function(snapshot8) {
  if (snapshot8.val() == '300234062056210')
  {
    this.setState({activeVehicle: 'van'});
  }
  else
  {
    this.setState({activeVehicle: 'helicopter'});
  }

  if (this.state.activeVehicle == 'van')
   {

   }
   else
   {

   }
}.bind(this));



    //[-83.4550, 42.3472]

this.dbRef.ref('active/' + this.props.tripno + '/positions').orderByKey().on("child_added", function(snapshot) {
            var data = snapshot.val();
          //  console.log(data);

              var newdata = {};
              newdata.keyer = snapshot.key;
              newdata.lat = Number(data.lat);
              newdata.lng = Number(data.lng);
              newdata.hdg = Number(data.hdg);
							if (data.speed)
							{
									newdata.speed = Number(data.speed);
							}

              var newpositions = this.state.positionsWithKeys;
              newpositions.push(newdata);
             // newpositions.sort(this.compare);
              var latestindex = newpositions.length - 1;
              this.setState({positionsWithKeys: newpositions});
              this.setState({latestposition: [newpositions[latestindex].lng, newpositions[latestindex].lat]});

             /* newpositions.forEach(function(value, idx) {

                    var positions = this.state.positions;
                    var lat = value.lat;
                    var lng = value.lng;
                      positions.push({lat, lng});
                    this.setState({positions: newpositions});
              }.bind(this));*/


// possible method for pushing to linedata implemented later on
//window.map.linedata.push([newpositions[latestindex].lng, newpositions[latestindex].lat]);



            var poslength = this.state.positionsWithKeys.length - 1;




    }.bind(this));

/*this.dbRef.ref('active/' + this.props.tripno + '/positions').on("child_moved", function(childSnapshot, prevChildKey) {
          //  console.log('child moved');
          //  console.log(childSnapshot);
          //  console.log(prevChildKey);
            //this.props.onReload();


});*/

/*
     var poslength = this.state.positions.length - 1;
        window.map.flyTo({center: this.state.positions[poslength]});*/
//$('main').css('background-color', 'rgb(248,249,250)');


  }

  componentDidUpdate() {
		if(this.props.pickupLiteral && this.props.deliveryLiteral)
		{

		}
   // show and hide appropriate layer
   if (this.state.activeVehicle == 'van')
   {

   }
   else
   {


   }
   if (this.props.showIt)
   {
    this.run();
   }
  }
  componentWillUnmount() {
    //window.map.removeLayer('route');
		google.maps.event.clearListeners(window.map, 'resize');
    this.dbRef.ref('active/' + this.props.tripno + '/positions').off();
    this.dbRef.ref('active/' + this.props.tripno + '/initial/imei').off();
    $('main').css('background-color', '#fff');
   //console.log("map unmounted");
    //$('.maponly').css('padding', '10px');
    //$('#map').addClass('hidden');
    var mapr = $('#map');
    $('#maphider').append(mapr);
     $('#map-target').empty();
     //window.map.off('load', this.addLayer);
  }
 compare(a,b) {
  // {keyer: '2018-08-10T09:09:55Z', lng: "", lat: ""}
  // 2018-08-10T09:09:55Z
//  console.log(a);
  var ayear = a.keyer.substring(0,4);
  var byear = b.keyer.substring(0,4);

  var amon = a.keyer.substring(5,7);
  var bmon = b.keyer.substring(5,7);

  var aday = a.keyer.substring(8,10);
  var bday = b.keyer.substring(8,10);

  var ahrs = a.keyer.substring(11,13);
  var bhrs = b.keyer.substring(11,13);
   var amins = a.keyer.substring(14, 16);
   var bmins = b.keyer.substring(14, 16);
   var asecs = a.keyer.substring(17, 19);
   var bsecs = b.keyer.substring(17, 19);
if (ayear > byear)
{
 return 1;
}
if (byear > ayear)
{
 return -1;
}
if (ayear == byear)
{
  if (amon > bmon)
  {
    return 1;
  }
  if (bmon > amon)
  {
    return -1;
  }
  if (amon == bmon)
  {
    if (aday > bday)
    {
      return 1;
    }
    if (bday > aday)
    {
      return -1;
    }
    if (aday == bday)
    {
        if (ahrs > bhrs)
    {
      return 1;
    }
    if (bhrs > ahrs)
    {
      return -1;
    }
    if (ahrs == bhrs)
    {
       if (amins > bmins)
       {
        return 1;
       }
       if (bmins > amins)
       {
        return -1;
       }
       if (bmins == amins)
       {
          if (asecs > bsecs)
          {
            return 1;
          }
          else if (bsecs > asecs)
          {
            return -1;
          }
          else
          {
            return 0;
          }
       }
    }
    }
  }
}




 }
  run() {
//console.log("map constructed");
        //this.listenForItems(this.itemsRef);
        //$('.maponly').css('padding', '0px');
        if (this.props.showIt)
        {
         // $('.maponly').css('padding', 'px');
          $('main').css('padding', '0px');
         var wid = $('#mn').width();
         //$('#mapcontainer').css('margin-left', '30px');
        }
        else
        {
          var wid = $('#size-target').width();
        }
//var wid = $(window).width();
if (this.props.showIt)
{
 var hei = '420px';
}
else {
   var hei = $('#form-outline').height();
}




    $('#map').css('width', wid);

    $('#map').css('height', hei);
    var maper = $('#map');
    $('#map-target').append(maper);
        // old mapbox method window.map.resize();

      $(window).resize(function() {
        google.maps.event.trigger(window.map, 'resize');
    });

        google.maps.event.trigger(window.map, 'resize');

  }
	niceETEString(ete)
	{
		if (ete < 60)
		{
			// less than 1 hour
			if (ete < 10)
			{
				var ete2 = ete + '';
				var etestring = ete2.substring(0,1) + ' mins';
			}
			else {
				var ete2 = ete + '';
				var etestring = ete2.substring(0,2) + ' mins';
			}
			return etestring;
			//this.setState({airete: etestring});
		}
		else {
			// its less than 10 hours
			var hrs = ete/60 + '';
			var hrsstr = hrs.substring(0,1);
			var mins = hrs.substring(1);
			var minsstring = mins*60 + '';
			var minsstring2 = minsstring.substring(0,2);
			//console.log(minsstring2);
			if(minsstring2.substring(1) == '.')
			{
				minsstring2 = minsstring.substring(0,1);
			}
			var etestring =  hrsstr + ' hours ' + minsstring2 + ' mins';
			return etestring;
			//this.setState({airete: etestring});
		}
	}
	render() {
		this.state.positionsWithKeys.sort(this.compare);
		window.linedata = [];

		this.state.positionsWithKeys.forEach(function(value, idx) {


					var lat = value.lat;
					var lng = value.lng;
						window.linedata.push({lat: lat, lng: lng});

		}.bind(this));
		window.line.setPath(window.linedata);
 const poslength = window.linedata.length - 1;

			if (this.state.to != undefined && this.state.from != undefined && this.state.to != '' && this.state.from != '')
			{
				window.forwardlinedata = [];
				//window.forwardlinedata = [{lng: -83.4550, lat: 42.3472}];
				// add logic of drawing line inbound to supplier or inbound to plant
				if (this.props.status == 'its')
				{
					if (poslength > 0)
					{
						window.forwardlinedata.push(window.linedata[poslength]);
					}
					else {
						window.forwardlinedata.push({lng: -83.4550, lat: 42.3472});
					}
					window.forwardlinedata.push(this.state.from);
					window.forwardlinedata.push(this.state.to);
				}
				else if (this.props.status == 'itp')
				{
					window.forwardlinedata.push(window.linedata[poslength]);
					window.forwardlinedata.push(this.state.to);
				}
        else{
          // freight delivered, delele linedata
          window.forwardlinedata = [];
        }


				window.forwardline.setPath(window.forwardlinedata);
			}

    //const linedata = this.state.positions;
   // console.log(linedata);




            /*      console.log(poslength);
                 console.log(linedata[poslength]);
        var marker = new google.maps.Marker({
            position: linedata[poslength],
            map: window.map,
            title: 'Current Location',
            //icon: google.maps.Icon({url: 'images/van.png'})
          });
          const positionCircle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: window.map,
      center: linedata[poslength],
      radius: 2000
    });*/


 if (poslength > 0)
 {
   window.shipicon.setPosition(window.linedata[poslength]);
    var angleDegrees = Number(this.state.positionsWithKeys[poslength].hdg);


    //console.log('rotating to:' + angleDegrees);
 var ptr = window.shipicon.getIcon();
 ptr.rotation = angleDegrees;
ptr.labelOrigin = new google.maps.Point(17,0);
 window.shipicon.setIcon(ptr);
			 if (this.state.activeVehicle == 'helicopter' && this.state.positionsWithKeys[poslength].speed && Number(this.state.positionsWithKeys[poslength].speed) > 40 && this.props.status == 'itp')
			 {
				 var speed = Number(this.state.positionsWithKeys[poslength].speed); //knots
				 var meterspersecond = speed*.514*2; // times 2 for spider api bug that halves the actual speed
				 // need to calculate the distance remaining
				 var shipnow = window.shipicon.getPosition(); // returns a google maps latlng obj for the compute distance function
				 var goingto = window.to.getPosition();
 						var meters = google.maps.geometry.spherical.computeDistanceBetween(shipnow, goingto);
						var minstotarget = (meters/meterspersecond)/3600*60; // mins decimal
						// TODO:: if hours>10 else then substring then divide by 60 then substring
						var etelabel = ' ETA: ' + this.niceETEString(minstotarget) + ' ';
				 		window.shipicon.setLabel({
							fontSize: '16px',
							className: 'etelabel',
							text: etelabel
						});
						window.shipicon.setVisible(true);
			 }
			 else if (this.state.activeVehicle == 'van' && this.state.positionsWithKeys[poslength].speed && Number(this.state.positionsWithKeys[poslength].speed) > 10 && this.props.status == 'itp')
			 {
				 var speed = Number(this.state.positionsWithKeys[poslength].speed); //knots
				 var meterspersecond = speed*.514*2; // times 2 for spider api bug that halves the actual speed
				 // need to calculate the distance remaining
				 var shipnow = window.shipicon.getPosition(); // returns a google maps latlng obj for the compute distance function
				 var goingto = window.to.getPosition();
						var meters = google.maps.geometry.spherical.computeDistanceBetween(shipnow, goingto);
						var minstotarget = (meters/meterspersecond)/3600*60; // mins decimal
						// TODO:: if hours>10 else then substring then divide by 60 then substring
						var etelabel = ' ETA: ' + this.niceETEString(minstotarget) + ' ';
						window.shipicon.setLabel({
							fontSize: '16px',
							className: 'etelabel',
							text: etelabel
						});
						window.shipicon.setVisible(true);
			 }
			 else {
				 window.shipicon.setLabel(null);
				 window.shipicon.setVisible(true);
			 }

       if (this.props.status == 'delivered')
       {
         // move ship to delivery, forward line is delted by code above
        window.shipicon.setLabel(null);
        window.shipicon.setPosition(this.state.to);
        window.shipicon.setVisible(true);
       }
   }
   else
   {
    window.shipicon.setPosition({lat: 42.3472, lng: -83.4550});
		window.shipicon.setVisible(false);
   }

        window.map.setCenter(window.linedata[poslength]);
  google.maps.event.trigger(window.map, 'resize');
		return (
      <div id="mapcontainer" className={this.props.showIt ? 'col-12 d-none d-md-block' : ''}>
        <div id="map-target"></div>
         </div>
      )
	}
}

export default TrackMap
