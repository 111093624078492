import React from 'react';
import firebase from 'firebase/app';

import $ from "jquery";
const google = window.google;
// requires global variables map, initMap(), window and jquery from index.html


class AvailabilityMap extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
			n556: {lng: -83.4550, lat: 42.3472},
			n76: {lng: -83.4550, lat: 42.3472},
			N431: {lng: -83.4550, lat: 42.3472},
			n556up: false,
			n76up: false,
			n431up: false

    };
    this.dbRef = firebase.database();

    // get tripno from props
		if (window.shipicon)
    {
      window.shipicon.setMap(null);
    }
		if (window.shipicon1)
		{
			window.shipicon1.setMap(null);
		}
		if (window.shipicon2)
    {
      window.shipicon2.setMap(null);
    }
		if (window.shipicon3)
    {
      window.shipicon3.setMap(null);
    }
    if (window.line)
    {
      window.line.setMap(null);
    }
		if (window.to)
		{
			window.to.setMap(null);
		}
		if (window.hangar)
		{
			window.hangar.setMap(null);
		}
		if (window.from)
		{
			window.from.setMap(null);
		}
		if (window.forwardline)
		{
			window.forwardline.setMap(null);
		}
		 window.shipicon1 = new google.maps.Marker({

    position: {lng: -83.4550, lat: 42.3472},
    map: window.map,
    icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 6,
        fillColor: "#08B2FC",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0 //this is how to rotate the pointer
    }
});

window.shipicon2 = new google.maps.Marker({

position: {lng: -83.4550, lat: 42.3472},
map: window.map,
icon: {
		 path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
	 scale: 6,
	 fillColor: "#08B2FC",
	 fillOpacity: 0.8,
	 strokeWeight: 2,
	 rotation: 0 //this is how to rotate the pointer
}
});

window.shipicon3 = new google.maps.Marker({

position: {lng: -83.4550, lat: 42.3472},
map: window.map,
icon: {
		 path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
	 scale: 6,
	 fillColor: "#08B2FC",
	 fillOpacity: 0.8,
	 strokeWeight: 2,
	 rotation: 0 //this is how to rotate the pointer
}
});

	}

  componentDidMount() {

     this.run();
		 // get the current asset postions
 		this.dbRef.ref('currentPositions').on('value', function(snapshot3) {
 			var posObj = snapshot3.val();
 			var n556 = posObj.n556uh; // these are lat lng literals
 			var n76 = posObj.n76dc;
 			var n431 = posObj.n431sl;
 			this.setState({n556: n556});
 			this.setState({n76: n76});
 			this.setState({n431: n431});

 		});


this.dbRef.ref('availability').once('value').then(vehicle => {
  // get the curren t availablity tomsee which tom diaply
	var availObj = vehicle.val();
	if (availObj.n556uh == 'available')
	{
		this.setState({n556up: true});
	}
	if (availObj.n76dc == 'available')
	{
		this.setState({n76dc: true});
	}
	if (availObj.n431sl == 'available')
		{
			this.setState({n431up: true});
		}

});


  }

  componentDidUpdate() {
		//later if we make this reak time need an unsubsribe here

  }
  componentWillUnmount() {
		window.shipicon1.setMap(null);
			window.shipicon2.setMap(null);
				window.shipicon3.setMap(null);
    var mapr = $('#map');
    $('#maphider').append(mapr);
     $('#map-target').empty();

  }

  run() {

		var hei = $('#mapsizer').height();
		var wid = $('#mapsizer').width();
    $('#map').css('width', wid);

    $('#map').css('height', hei);
    var maper = $('#map');
    $('#map-target').append(maper);
        // old mapbox method window.map.resize();

      $(window).resize(function() {
        google.maps.event.trigger(window.map, 'resize');
    });

        google.maps.event.trigger(window.map, 'resize');

  }

	render() {






   window.shipicon1.setPosition(this.state.n556);
	  window.shipicon2.setPosition(this.state.n76);
		 window.shipicon3.setPosition(this.state.n431);
    //var angleDegrees = Number(this.state.positionsWithKeys[poslength].hdg);
  //  console.log('rotating to:' + angleDegrees);
	 /*var ptr = window.shipicon.getIcon();
	 ptr.rotation = angleDegrees;
	 window.shipicon.setIcon(ptr);*/


  //      window.map.setCenter(window.linedata[poslength]);

		return (
      <div id="mapcontainer" className='col-12 d-xs-block'>
        <div id="map-target"></div>
         </div>
      )
	}
}

export default AvailabilityMap
