import React from 'react';
import firebase from 'firebase/app';
import $ from "jquery";
class MobileNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

    };

	}

  componentDidMount() {

  }

  onLogClick(e) {
    e.preventDefault();
   // this.setState({mobileState: 'log'});
   this.props.onLinkClick('log');
  }
  onChatClick(e) {
    e.preventDefault();
    //this.setState({mobileState: 'chat'});
      this.props.onLinkClick('chat');
  }
  onMapClick(e) {
    e.preventDefault();
    //this.setState({mobileState: 'map'});
      this.props.onLinkClick('map');
  }
	render() {
    return (
      <div id="mn" className="row d-md-none nav-fb">
              <div className="col-4 blink">
              <a href="#" onClick={this.onLogClick.bind(this)}>
                <div><img className="navicon" src="images/home_icon.png" /></div>
                <div>Log</div>
                </a>
              </div>
              <div className="col-4 blink">
              <a href="#" onClick={this.onMapClick.bind(this)}>
                <div><img className="navicon" src="images/map_icon.png" /></div>
                <div>Map</div>
                </a>
              </div>

              <div className="col-4 blink">
              <a href="#" onClick={this.onChatClick.bind(this)}>
                <div><img className="navicon" src="images/chat_icon.png" /></div>
                <div>Chat</div>
                </a>
              </div>
          </div>
      )
		}
}

export default MobileNav
