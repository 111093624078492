import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import $ from "jquery";

class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state={
      email: '',
      password: '',
      fn: '',
      ln: '',
      error: '',
      jobTitle: '',
      company: '',
      phoneNumber: '',
      feedback: '',
      termsChecked: false,
      disabled: false
    }
    this.dbRef = firebase.database();
	}

  componentDidMount() {

  }
   onTermsChecked(e) {

    if(e.target.checked)
    {
      this.setState({termsChecked: true});
    }
    else
    {
      this.setState({termsChecked: false});
    }
  }
  register(e) {
    e.preventDefault();
    this.setState({feedback: ''});
    if (!this.state.termsChecked)
    {
      this.setState({feedback: "You must agree to the terms and conditions."});
      return false;
    }
    if (!this.validateFN(this.state.fn))
    {
      this.setState({feedback: "First name must be less than 30 characters."});
      return false;
    }
    if(!this.validateLN(this.state.ln))
    {
        this.setState({feedback: "Last name must be less than 30 characters."});
      return false;
    }
     if(!this.validateTitle(this.state.jobTitle))
    {
        this.setState({feedback: "Job title name must be less than 30 characters."});
      return false;
    }
     if(!this.validateCompany(this.state.company))
    {
      this.setState({feedback: "Company name must be less than 30 characters."});
      return false;
    }
     if(!this.validatePhone(this.state.phoneNumber))
    {
      this.setState({feedback: "Phone number must be a valid format, or leave blank. Example: (555) 555-5555."});
      return false;
    }
    var email = this.state.email;
    var password = this.state.password;
    var name = this.state.fn;



    this.setState({disabled: true});
    firebase.auth().createUserWithEmailAndPassword(email, password).then((userCred) => {
      //console.log("profile stuff fired");

      this.dbRef.ref('users/' + userCred.user.uid + '/company').set(this.state.company);
      this.dbRef.ref('users/' + userCred.user.uid + '/phone').set(this.state.phoneNumber);
      this.dbRef.ref('users/' + userCred.user.uid + '/title').set(this.state.jobTitle);
        userCred.user.updateProfile({

  displayName: name,

}).then(() => {
//  console.log("display name set");

  //this.onRegisterComplete();
}).catch((error) => {
  this.setState({error: error.message});
  this.setState({disabled: false});
});

    }).catch((error) => {
  // Handle Errors here.
  var errorCode = error.code;
  var errorMessage = error.message;
  this.setState({error: error.message});
});

  }
  onFNInputChange(e) {
    this.setState({fn: e.target.value});
    if(/[^a-zA-Z]/.test(e.target.value)){
      //$('#help').text("Letters only.");
      //$('#registersubmit').addClass("disabled");
    }
    else
    {
      //$('#registersubmit').removeClass("disabled");
    }
  }
  onLNInputChange(e) {
    this.setState({ln: e.target.value});
     if(/[^a-zA-Z]/.test(e.target.value)){
    //  $('#help').text("Letters only.");
    //  $('#registersubmit').addClass("disabled");
    }
    else
    {
      //$('#registersubmit').removeClass("disabled");
    }
  }
  onEmailInputChange(e) {
    this.setState({email: e.target.value});
  }
  onPasswordInputChange(e) {
    this.setState({password: e.target.value});
    if (e.target.value.length < 5)
    {
     this.setState({feedback: "Passwords must be more than 5 characters."});
    }
    else if (e.target.value.length > 20)
    {
      this.setState({feedback: "Passwords must be less than 20 characters."});
    }
    else
    {
      this.setState({feedback: ''});
    }
  }
  validateFN(name) {
    if (name.length > 30)
    {
      return false;
    }
    else
    {
      return true;
    }
  }
  validateLN(name) {
    if (name.length > 30)
    {
      return false;
    }
    else
    {
      return true;
    }
  }
  validateTitle(title) {
    if (title.length > 30)
    {
      return false;
    }
    else
    {
      return true;
    }
  }
  validateCompany(company) {
    if (company.length > 29)
    {
      return false;
    }
    else
    {
      return true;
    }
  }
  validatePhone(pn) {
    if (pn.length > 29)
    {
      return false;
    }
    else
    {
      return true;
    }
 }
 onCompanyChange(e) {
  e.preventDefault();
  this.setState({company: e.target.value});
 }
 onJobTitleChange(e) {
    e.preventDefault();
    this.setState({jobTitle: e.target.value});
  }
  onPhoneNumberChange(e) {
    e.preventDefault();
    this.setState({phoneNumber: e.target.value});
  }

  onBackToSignIn(e) {
    e.preventDefault();
    this.props.onBack();
  }
	render() {
    return (

      <form className="p-4 p-md-5 border rounded-3 bg-light">
      {this.state.error == '' ? '': <div className="alert alert-danger alert-dismissible fade show" role="alert">{this.state.error}</div>}
      {this.state.feedback == '' ? '': <div className="alert alert-danger alert-dismissible fade show" role="alert">{this.state.feedback}</div>}
			 <h3 className="form-signin-heading">Register</h3>

			 <div class="mb-3">
        <label htmlFor="inputName" className="form-label">Name</label>
        <input type="email" id="inputName" className="form-control" placeholder="Name" onChange={this.onFNInputChange.bind(this)} value={this.state.fn} maxLength="41" />
		   </div>



			 <div class="mb-3">
				 <label htmlFor="inputCompany" className="form-label">Company</label>
         <input type="email" id="inputCompany" className="form-control" placeholder="Company" onChange={this.onCompanyChange.bind(this)} value={this.state.company} maxLength="29" />
			</div>

				<div class="mb-3">
         <label htmlFor="inputTitle" className="form-label">Job Title</label>
         <input type="email" id="inputTitle" className="form-control" placeholder="Job Title (optional)" onChange={this.onJobTitleChange.bind(this)} value={this.state.jobTitle} maxLength="29" />
				</div>

				<div class="mb-3">
         <label htmlFor="inputPhone" className="form-label">Work Phone</label>
         <input type="email" id="inputPhone" className="form-control" placeholder="Phone Number (optional)" onChange={this.onPhoneNumberChange.bind(this)} value={this.state.phoneNumber} maxLength="16"/>
				</div>

				<div class="mb-3">
        <label htmlFor="inputEmail" className="form-label">Email address</label>
        <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required onChange={this.onEmailInputChange.bind(this)} value={this.state.email} maxLength="100" />
				</div>
				<div class="mb-3">
			  <label htmlFor="inputPassword" className="form-label">Password</label>
        <input type="password" id="inputPassword" className="form-control" placeholder="Password" required onChange={this.onPasswordInputChange.bind(this)} value={this.state.password} maxLength="50" />
				</div>
				<div className="form-check">

            <input type="checkbox" className="form-check-input" onChange={this.onTermsChecked.bind(this)} checked={this.state.termsChecked} />
          <label className="form-check-label"> I have read and agree to the <a href="https://go.mcmahonnow.com/terms.html" target="_blank" rel="noopener">Terms and Conditions</a>.</label>
        </div>


        <div class="mb-3"><button id="registersubmit" className="btn btn-lg btn-primary btn-block" type="submit" onClick={this.register.bind(this)} style={{marginTop: "10px"}}>Register</button>
</div><a href="#" onClick={this.onBackToSignIn.bind(this)}>Back to Sign In</a>
			</form>


      )
		}

}
export default Register
