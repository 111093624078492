import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import firebase from 'firebase/app';
import "firebase/analytics";
import { } from 'firebase/app-check';

var config = {
apiKey: "AIzaSyBmT5umKSDy39RwnbcvWss4VT09H4BdRJ4",
authDomain: "mhsnow-b89d3.firebaseapp.com",
databaseURL: "https://mhsnow-b89d3.firebaseio.com",
projectId: "mhsnow-b89d3",
storageBucket: "mhsnow-b89d3.appspot.com",
messagingSenderId: "843813336960",
appId: "1:843813336960:web:fc5c5c0b0f226b4e963365",
measurementId: "G-8LHLG2YKZR"
};

firebase.initializeApp(config);
const analytics = firebase.analytics();
const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  '6LfHRIokAAAAAPjcrNtEFwj3dMPgMVwCAZpenUOW',

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
